import React, { useState, useEffect } from "react";
import axios from "axios";
import params from "../../../config/Params";
import { getItemFromLocalStorage } from "../../../utils/storageUtils";
import { toast } from "react-toastify";

const Rooms = ({ formData, nextStep, prevStep }) => {

  const { listingId } = formData;
  const [rooms, setRooms] = useState([]);
  const [showRoomForm, setShowRoomForm] = useState(true);
  const [currentRoomIndex, setCurrentRoomIndex] = useState(0);
  const [roomId, setRoomId] = useState(null);
  const [description, setDescription] = useState('');
  const [charLimit] = useState(500);
  const [roomName, setRoomName] = useState('');
  const [roomCount, setRoomCount] = useState(1);
  const [mealOption, setMealOption] = useState('');
  const [roomType, setRoomType] = useState('');
  const [bedType, setBedType] = useState('');
  const [roomSize, setRoomSize] = useState('');
  const [roomView, setRoomView] = useState('');
  const [smokingAllowed, setSmokingAllowed] = useState('1');
  const [extraBed, setExtraBed] = useState(false);
  const [extraBedType, setExtraBedType] = useState('');
  const [baseAdults, setBaseAdults] = useState(1);
  const [maxAdults, setMaxAdults] = useState(1);
  const [maxChildren, setMaxChildren] = useState(1);
  const [maxOccupancy, setMaxOccupancy] = useState(1);
  const [baseRate, setBaseRate] = useState('');
  const [extraAdultCharge, setExtraAdultCharge] = useState('');
  const [childCharges, setChildCharges] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= charLimit) {
      setDescription(value);
    }
  };

  const handleIncrement = (value, setter, max = 10) => {
    if (value < max) {
      setter(value + 1);
    }
  };

  const handleDecrement = (value, setter, min = 1) => {
    if (value > min) {
      setter(value - 1);
    }
  };

  useEffect(() => {      
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          setRooms(response.data.updated_property_item.rooms || []);
          setShowRoomForm(response.data.updated_property_item.rooms === 0);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
  }, []);

  const handleSaveRoom = () => {
    const newRoom = {
      stay_listing_id : listingId,
        room_name : roomName,
        room_description : description,
        no_of_rooms : roomCount,
        no_of_rooms: roomCount,
        meal_option: mealOption,
        room_type: roomType,
        bed_type: bedType,
        room_size: roomSize,
        room_view: roomView,
        is_smoking_allowed: smokingAllowed === "2",
        is_provide_extra_bed: extraBed,
        extra_bed_type: extraBedType,
        base_adults: baseAdults,
        maximum_adults: maxAdults,
        maximum_children: maxChildren,
        maximum_occupancy: maxOccupancy,
        base_room_price: baseRate,
        extra_adult_charge: extraAdultCharge,
        extra_child_charge: childCharges,
        booking_start_date: startDate,
        booking_end_date: endDate,
        room_status: 1
    };

    setRooms([...rooms, newRoom]);
    setShowRoomForm(false);
    setCurrentRoomIndex(rooms.length);

    saveListing();
  };

  const handleEditRoom = (index) => {
    const roomToEdit = rooms[index];
    setShowRoomForm(true);
    setCurrentRoomIndex(index);
    
    setRoomId(roomToEdit.hotel_room_id);
    setRoomName(roomToEdit.room_name);
    setRoomCount(roomToEdit.no_of_rooms);
  };

  const handleDeleteRoom = (index) => {
    const updatedRooms = [...rooms];
    updatedRooms.splice(index, 1);
    setRooms(updatedRooms);
  };

  const handleAddRoom = () => {
    setShowRoomForm(true);
    setCurrentRoomIndex(rooms.length);
  };

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage("hostProfile");
    const ts = Date.now();

    const roomData = [
      {
        hotel_room_id : roomId,
        stay_listing_id : listingId,
        room_name : roomName,
        room_description : description,
        no_of_rooms : roomCount,
        no_of_rooms: roomCount,
        meal_option: mealOption,
        room_type: roomType,
        bed_type: bedType,
        room_size: roomSize,
        room_view: roomView,
        is_smoking_allowed: smokingAllowed === "2",
        is_provide_extra_bed: extraBed,
        extra_bed_type: extraBedType,
        base_adults: baseAdults,
        maximum_adults: maxAdults,
        maximum_children: maxChildren,
        maximum_occupancy: maxOccupancy,
        base_room_price: baseRate,
        extra_adult_charge: extraAdultCharge,
        extra_child_charge: childCharges,
        booking_start_date: startDate,
        booking_end_date: endDate,
        room_status: 1
      },
    ];

    let catData = new FormData();
    catData.append("token", hostData.host_access_token);
    catData.append("listing_id", listingId);
    catData.append("data", JSON.stringify({rooms: roomData}));
    catData.append("ts", ts);

    try {
      const response = await axios({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });

      if (response.data.status === "done") {
        nextStep();
      } else {
        toast.error("Failed to save data");
      }
    } catch (err) {
      toast.error("An error occurred while saving the listing");
    }
  };

  const handleNext = () => {
    saveListing();
  };

  return (
    <div>
        <div className="clp-header">
            <h4 className='ws-title'>Rooms</h4>
            <p className="ws-desc">Define Rooms available at your property and the amenities for each type of room</p>
        </div>

        {showRoomForm ? (
          <div>
            <div className="add-room-section hmfsb">

              <div className="room-detail-box">

                <div className="adrotibx">
                  <h4 className='ws-title'>Rooms</h4>
                  <p className="ws-desc mb-3">Add room level details</p>
                </div>
                
                <div className='row'>
                  <div className='col-md-6'>
                    <div className="form-box">
                      <label for="roomName" class="form-label">Room Name</label>
                      <input type="text" class="form-control" id="roomName" placeholder="Room Name" value={roomName} onChange={(e) => setRoomName(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-box'>
                      <label for="roomDesc" class="form-label">Room Description</label>
                      <textarea
                        className='form-control'
                        id='roomDesc'
                        rows={5}
                        value={description}
                        onChange={handleDescriptionChange}
                      />
                      <div className='hhtbs'>
                      {charLimit - (description?.length || 0)} characters available
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="roomCount" class="form-label">Number of Available Room(s)</label>
                      <input type="text" class="form-control" id="roomCount" placeholder="Enter Room Count" value={roomCount} onChange={(e) => setRoomCount(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="roomCount" class="form-label">Meal Options</label>
                      <select class="form-select" value={mealOption} onChange={(e) => setMealOption(e.target.value)}>
                          <option selected>select</option>
                          <option value="1">Accomodation only</option>
                          <option value="2">Free Breakfast</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="roomCount" class="form-label">Room Type</label>
                      <select class="form-select" value={roomType} onChange={(e) => setRoomType(e.target.value)}>
                          <option selected>select</option>
                          <option value="1">Deluxe</option>
                          <option value="2">Standard</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="roomCount" class="form-label">Bed Type</label>
                      <select class="form-select" value={bedType} onChange={(e) => setBedType(e.target.value)}>
                          <option selected>select</option>
                          <option value="1">king Bed</option>
                          <option value="2">Queen Bed</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="roomSize" class="form-label">Room Size (Area)</label>
                      <input type="text" class="form-control" id="roomSize" placeholder="Enter Room Size" value={roomSize} onChange={(e) => setRoomSize(e.target.value)} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="roomCount" class="form-label">Room View (Optional)</label>
                      <select class="form-select" value={roomView} onChange={(e) => setRoomView(e.target.value)}>
                          <option selected>select</option>
                          <option value="1">Sea View</option>
                          <option value="2">Hill View</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="roomCount" class="form-label">Smoking Allowed</label>
                      <select class="form-select" value={smokingAllowed} onChange={(e) => setSmokingAllowed(e.target.value)}>
                          <option value="1">No</option>
                          <option value="2">Yes</option>
                      </select>
                    </div>
                  </div>
                </div>

              </div>

              <div className="room-detail-box">
                <div>
                  <p className='rdb-title'>Extra Bed</p>
                </div>
                <div className='dypxbs'>
                  <div>
                    <p>Do you provide extra bed?</p>
                  </div>
                  <div class="extra-bed-radio">
                    <div className='exdrs'>
                      <label>
                        <input type="radio" name="radio" checked="" />
                        <span>No</span>
                      </label>
                      <label>
                        <input type="radio" name="radio" />
                        <span>Yes</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="extraBed" class="form-label">Extra Bed Type</label>
                      <select class="form-select" value={extraBedType} onChange={(e) => setExtraBedType(e.target.value)}>
                          <option selected>select</option>
                          <option value="1">Mattress</option>
                          <option value="2">Cot</option>
                          <option value="3">Sofa Cum Bed</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="room-detail-box">
                <div>
                  <p className='rdb-title'>Room Occupancy</p>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='house-rules-box pt-0'>
                      <div className='rules-guest-box'>
                        <div className='hrbss'>
                          <label className='st1-label'>Base Adults</label>
                          <p className='label-hint'>Ideal number of adults that can be accomodated in this room. Occupancy calculations are based on the accommodation of two adults per room.</p>
                        </div>
                        <div className="input-number-container">
                            <span className="input-number-decrement" onClick={() => handleDecrement(baseAdults, setBaseAdults)}>–</span>
                            <input
                                name='baseAdults'
                                className="input-number"
                                type="number"
                                value={baseAdults}
                                readOnly
                            />
                            <span className="input-number-increment" onClick={() => handleIncrement(baseAdults, setBaseAdults)}>+</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='house-rules-box'>
                      <div className='rules-guest-box'>
                        <div className='hrbss'>
                          <label className='st1-label'>Maximum Adults</label>
                          <p className='label-hint'>Maximum number of adults that can be accommodated in this room. Occupancy calculations are determined by the accommodation of two adults in a room, without the addition of extra beds.</p>
                        </div>
                        <div className="input-number-container">
                            <span className="input-number-decrement" onClick={() => handleDecrement(maxAdults, setMaxAdults)}>–</span>
                            <input
                                name='maxAdults'
                                className="input-number"
                                type="number"
                                value={maxAdults}
                                readOnly
                            />
                            <span className="input-number-increment" onClick={() => handleIncrement(maxAdults, setMaxAdults)}>+</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='house-rules-box'>
                      <div className='rules-guest-box'>
                        <div className='hrbss'>
                          <label className='st1-label'>Maximum Children</label>
                          <p className='label-hint'>Mention the number of maximum children who are allowed to stay in the room</p>
                        </div>
                        <div className="input-number-container">
                            <span className="input-number-decrement" onClick={() => handleDecrement(maxChildren, setMaxChildren)}>–</span>
                            <input
                                name='maxChildren'
                                className="input-number"
                                type="number"
                                value={maxChildren}
                                readOnly
                            />
                            <span className="input-number-increment" onClick={() => handleIncrement(maxChildren, setMaxChildren)}>+</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='house-rules-box'>
                      <div className='rules-guest-box'>
                        <div className='hrbss'>
                          <label className='st1-label'>Maximum Occupancy</label>
                          <p className='label-hint'>Specify the maximum number of adults & children that can be accommodated in this room</p>
                        </div>
                        <div className="input-number-container">
                            <span className="input-number-decrement" onClick={() => handleDecrement(maxOccupancy, setMaxOccupancy)}>–</span>
                            <input
                                name='maxOccupancy'
                                className="input-number"
                                type="number"
                                value={maxOccupancy}
                                readOnly
                            />
                            <span className="input-number-increment" onClick={() => handleIncrement(maxOccupancy, setMaxOccupancy)}>+</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="room-detail-box">
                <div>
                  <p className='rdb-title'>Base Room Price</p>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="baseRate" class="form-label">Base Rate</label>
                      <input type="text" class="form-control" id="baseRate" placeholder="Add Rate" value={baseRate} onChange={(e) => setBaseRate(e.target.value)} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="extraAdultCharge" class="form-label">Extra Adult Charge (Optional)</label>
                      <input type="text" class="form-control" id="extraAdultCharge" placeholder="Add Rate" value={extraAdultCharge} onChange={(e) => setExtraAdultCharge(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="childCharges" class="form-label">Charges for child (7-17 yrs) (Optional)</label>
                      <input type="text" class="form-control" id="childCharges" placeholder="Add Rate" value={childCharges} onChange={(e) => setChildCharges(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="room-detail-box">
                <div>
                  <p className='rdb-title mb-0'>Availability</p>
                  <p className='label-hint'>Please select the start & end dates on which your property can be booked by guests.</p>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="startDate" class="form-label">Start Date</label>
                      <input type="date" class="form-control" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div class="form-box">
                      <label for="endDate" class="form-label">End Date</label>
                      <input type="date" class="form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>

              {/*<div className="room-detail-box">
                <div>
                  <p className='rdb-title mb-0'>Room Amenities</p>
                  <p className='label-hint'>Answering the amenities available at your property can significantly influence guests to book! Please answer the <strong>Mandatory Amenities</strong> available below</p>
                </div>
              </div>*/}

              <button className='btn btn-black' onClick={handleSaveRoom}>Save Room</button>

            </div>
           
          </div>
        ) : (
          <div>
            {rooms.length > 0 ? (
              rooms.map((room, index) => (
                <div className="room-box" key={index}>
                  <div className="rbwnedb">
                    <p>{room.room_name}</p>
                    <div className="room-action-btn-box">
                      <button onClick={() => handleEditRoom(index)}><i class="fas fa-pencil-alt"></i></button>
                      <button onClick={() => handleDeleteRoom(index)}><i class="fas fa-trash-alt"></i></button>
                    </div>
                  </div>
                  <div>
                    <p>Available Rooms : {roomCount}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No rooms added yet</p>
            )}
            <button className='btn btn-black' onClick={handleAddRoom}>Add Room</button>
          </div>
        )}

        <div className='clp-footer'>
          <button className='btn btn-blank' onClick={prevStep}>Back</button>
          <button className='btn btn-black' onClick={handleNext}>Next</button>
        </div>

    </div>
  );
};

export default Rooms;
