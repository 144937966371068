import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';

const ListingStep14 = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const [price, setPrice] = useState(0); 
  const [hostFee, setHostFee] = useState(0);
  const [affiliateId, setAffiliateId] = useState('');
  const numericPrice = parseFloat(price) || 0;
  const guestServiceFee = (numericPrice * 0.1).toFixed(2);
  const guestPriceBeforeTaxes = (numericPrice + parseFloat(guestServiceFee)).toFixed(2);
  const userEarns = (numericPrice);

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;
          try {
            setPrice(listingData.listing_price || 0);
            setHostFee(listingData.listing_host_fees || 0);
            setAffiliateId(listingData.affiliate_id || '');
          } catch (error) {
            toast.error('Error parsing listing data: ' + error.message);
            console.error('Parsing error:', error);
          }
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
  }, [listingId]); 

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const updatedPropertyItem = {
      listing_price: price,
      listing_guest_fees: guestServiceFee,
      listing_host_fees: hostFee
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleNext = () => {
    saveListing();
  };

  return (
    <div className='clss'>
      <div className='clp-header'>
        <h4 className='ws-title'>Now, set your price</h4>
        <p className='ws-desc'>You can change it anytime.</p>
      </div>

      <div className='st1-desc-box'>
        <div className='row'>
          <div className='col-md-12 text-center mb-3'>
            <div className='clsp-div'>
              <input 
                className='price-input' 
                type='number' 
                value={price} 
                onChange={(e) => setPrice(e.target.value)}
                min="0" 
                autofocus="true"
              />
            </div>
            <div className='clspb-div'>
              <div className='clspb-box'>
                <span>Base Price</span>
                <span>{numericPrice.toFixed(2)}</span>
              </div>
              <div className='clspb-box'>
                <span>Guest Service Fee</span>
                <span>{guestServiceFee}</span>
              </div>
              <div className='clspb-box'>
                <span>Guest Price Before Taxes</span>
                <span>{guestPriceBeforeTaxes}</span>
              </div>
            </div>
            <div className='clspb-div'>
              <div className='clspb-box border-0'>
                <span>You Earn</span>
                <span>{userEarns}</span>
              </div>
              { affiliateId && (
                <div className='affiliate-calculation-box'>
                  <p className='aff-calc-title'>Your earning after Affiliate Charges</p>
                  <div className='affiliate-calc'>
                    <div className='aff-calc-img'>
                      <img src={'/assets/img/icons/wsac_1.svg'} alt="Notification" className="wsac-icon" />
                      <span>month 10% of {userEarns} = {(userEarns * 0.1).toFixed(2)}</span>
                    </div>
                    <span>{(userEarns - userEarns * 0.1).toFixed(2)}</span>
                  </div>
                  <div className='affiliate-calc'>
                    <div className='aff-calc-img'>
                      <img src={'/assets/img/icons/wsac_2.svg'} alt="Notification" className="wsac-icon" />
                      <span>month 5% of {userEarns} = {(userEarns * 0.05).toFixed(2)}</span>
                    </div>
                    <span>{(userEarns - userEarns * 0.05).toFixed(2)}</span>
                  </div>
                  <div className='affiliate-calc'>
                    <div className='aff-calc-img'>
                      <img src={'/assets/img/icons/wsac_3.svg'} alt="Notification" className="wsac-icon" />
                      <span>month 3% of {userEarns} = {(userEarns * 0.03).toFixed(2)}</span>
                    </div>
                    <span>{(userEarns - userEarns * 0.03).toFixed(2)}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default ListingStep14;
