import React, { useState, useEffect, useRef, useCallback } from 'react';
import { db } from '../config/firebaseConfig';
import { ref, onChildAdded, push } from 'firebase/database';
import { getAuth, signInWithCustomToken } from "firebase/auth";
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';

function Chat({ chatData, onClose }) {

    const [messages, setMessages] = useState([]);
    //const [ctoken, setCtoken] = useState('');
    const [loading, setLoading] = useState(true);
    const [newMessage, setNewMessage] = useState('');

    const chatBodyRef = useRef(null);
    const auth = getAuth();
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    const messagesRef = ref(db, `messages/${userId}/${chatData.request_from}`);
    const inMessagesRef = ref(db, `messages/${chatData.request_from}/${userId}`);

    useEffect(() => {

        const getChatDB = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('sender_type', 2);
            setLoading(true);
    
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.getChatdb}`,
                    data: formData,
                });
    
                if (response.data.status === 'ok') {
                    firebaseAuth(response.data.dtoken);
                } else {
                    toast.error('An error occurred while fetching data.');
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
    
        getChatDB();
    }, []);

    const firebaseAuth = (token) => {
        signInWithCustomToken(auth, token)
            .then((userCredential) => {
                console.log("User signed in:", userCredential.user);
            })
            .catch((error) => {
                console.error("Error signing in:", error.code, error.message);
            });
    };

    useEffect(() => {

        setMessages([]);

        const fetchMyRequests = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('request_key', chatData.request_key);
            formData.append('receiver_id', chatData.request_from);
            formData.append('profile_type', chatData.request_from_type);
            formData.append('sender_type', 2);
            setLoading(true);

            let allMessages = [];
            let currentPage = 1;
            let totalPages = 1;

            try {
                do {
                    const response = await axios({
                        method: 'post',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        url: `${params.baseURL}${params.endpoints.latestMessages}`,
                        data: formData,
                        params: { page: currentPage }
                    });
        
                    if (response.data.items && Array.isArray(response.data.items)) {
                        allMessages = [...allMessages, ...response.data.items];
                        totalPages = response.data._meta.pageCount;
                        currentPage += 1;
                    } else {
                        toast.error('An error occurred while fetching data.');
                        break;
                    }
                } while (currentPage <= totalPages);
        
                setMessages(allMessages);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        fetchMyRequests();

    }, [chatData]);

    useEffect(() => {
       // console.log("Listening to messages at:", `messages/${userId}/${chatData.request_from}`);
        const processedMessageIds = new Set();
    
        const transformMessage = (firebaseMessage, messageType) => ({
            from_name: messageType === 'received' ? chatData.request_from_name : 'ME',
            is_read: firebaseMessage.read || null,
            mdate: firebaseMessage.mdate || new Date().toLocaleDateString(),
            message_id: firebaseMessage.message_id,
            message_text: firebaseMessage.message,
            modified_on: null,
            mtime: firebaseMessage.mtime,
            mtimewithdate: `${firebaseMessage.mdate} @ ${firebaseMessage.mtime}`,
            mtype: messageType,
            read_on: null,
            received_from_ip: null,
            receiver_id: firebaseMessage.to,
            request_id: chatData.request_key,
            rt_key: firebaseMessage.tmp_id,
            sender_id: firebaseMessage.from,
            sent_from_ip: null,
            sent_header_date: null,
            sent_on: new Date().toISOString(),
            tmp_id: firebaseMessage.tmp_id,
            to_name: messageType === 'received' ? 'ME' : chatData.request_from_name,
            to_pic: null,
            used_sticker_id: null,
            whose: messageType === 'received' ? 'from' : 'to',
        });
    
        const unsubscribeMessages = onChildAdded(messagesRef, (snapshot) => {
            if (snapshot.exists()) {
                const firebaseMessage = snapshot.val();
                
                if (!processedMessageIds.has(firebaseMessage.message_id)) {
                    const formattedMessage = transformMessage(firebaseMessage, 'send');
                    setMessages((prevMessages) => [...prevMessages, formattedMessage]);
                    processedMessageIds.add(firebaseMessage.message_id);
                }
            } else {
                console.error('No new messages in the snapshot');
            }
        });
    
        const unsubscribeIncomingMessages = onChildAdded(inMessagesRef, (snapshot) => {
            if (snapshot.exists()) {
                const firebaseMessage = snapshot.val();
                
                if (!processedMessageIds.has(firebaseMessage.message_id)) {
                    const formattedMessage = transformMessage(firebaseMessage, 'received');
                    setMessages((prevMessages) => [...prevMessages, formattedMessage]);
                    processedMessageIds.add(firebaseMessage.message_id);
                }
            } else {
                console.error('No new messages in the snapshot');
            }
        });
    
        return () => {
            unsubscribeMessages();
            unsubscribeIncomingMessages();
        };
    }, [chatData, userId]);
    

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
        //console.log(messages);
    }, [messages]);

    const handleSendMessage = useCallback(async () => {

        const currentMessage = newMessage.trim();
        setNewMessage(''); 

        if (!currentMessage) {
            return;
        }

        const hostData = getItemFromLocalStorage('hostProfile');
        const temp_id = 'WEB' + Math.random().toString(36).substring(2, 12);
        const messageData = {
            mtype: 'send',
            message_text: newMessage,
            sender: 'ME',
            timestamp: new Date().toISOString(),
            chatId: chatData.request_key,
            temp_id
        };  

        try {
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('request_key', chatData.request_key);
            formData.append('message', newMessage);
            formData.append('receiver_id', chatData.request_from);
            formData.append('profile_type', chatData.request_from_type);
            formData.append('tmp_id' , temp_id);
            formData.append('sender_type', 2);

            const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.sendMessage}`,
                data: formData
            });

            if (response.data.status === 'sent') {
                await push(messagesRef, { ...messageData, timestamp: new Date().toISOString() });
                //setMessages((prevMessages) => [...prevMessages, messageData]);
                setNewMessage('');
            } else {
                toast.error('Failed to send message');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while sending the message');
        }
    }, [newMessage, chatData]);

    const handleCloseBtn = () => {
        onClose();
        setNewMessage([]);
    };
    
    return (
        <div>
            <div className='chat-header'>
                <div className='chat-header-img'>
                    <img 
                        className='mru-pic' 
                        src={chatData.request_from_pic !== null ? chatData.request_from_pic : '/assets/img/ws_user_icon.png'}
                        alt='' 
                    />
                    <p className='chat-user-name'>{chatData.request_from_name}</p>
                </div>
                <button className='chat-close-btn' onClick={handleCloseBtn}>
                    <img src='/assets/img/icons/close_icon.svg' alt='' />
                </button>
            </div>
            <div className='chat-body' ref={chatBodyRef}>
            {messages
                //.filter((msg) => msg.message_id !== null && msg.message_id !== undefined)
                .map((msg) => (
                <div key={msg.message_id}>
                {msg.mtype === 'date' ? (
                    <div className='chat-date'>
                        <p>{msg.mdate}</p>
                    </div>
                ) : (
                    <div className={`chat-body-box ${msg.mtype === 'send' ? 'sender' : 'receiver'}`}>
                        <div className='chat-title-time'>
                            <p className='chat-profile-name'>{msg.mtype === 'send' ? 'ME' : msg.from_name}</p>
                            <p>{msg.mtime}</p>
                        </div>
                        <div className='chat-message'>
                            <span>{msg.message_text}</span>
                        </div>
                    </div>
                )}
                </div>
            ))}
            </div>

            <div className='chat-send-box'>
                <div>
                    <div className='chat-input-area'>
                        <textarea
                            className='form-control chat-input'
                            placeholder='Write your message...'
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            rows={1}
                        />
                        <button onClick={handleSendMessage} className='chat-send-btn'>
                            <img src='/assets/img/icons/send.svg' alt='' className='img-fluid' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat;