import React, { useEffect } from "react";
import categoryColors from "./config/categoryColors";
import { applyCategoryTheme } from "./utils/themeUtils";

const ThemeWrapper = ({ categoryId, children }) => {
  useEffect(() => {
    if (categoryId && categoryColors[categoryId]) {
      applyCategoryTheme(categoryId, categoryColors);
    }
    return () => {
      applyCategoryTheme(null, categoryColors);
    };
  }, [categoryId]);

  return <div>{children}</div>;
};

export default ThemeWrapper;
