import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';

const ListingStep9 = ({ nextStep, prevStep, formData }) => {

  const { listingId } = formData;
  const [title, setTitle] = useState('');
  const [charLimit] = useState(32);

  useEffect(() => {
  
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          setTitle(response.data.updated_property_item.listing_title);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
    return () => {
      setTitle('');
    };
  }, []);

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const updatedPropertyItem = {
      listing_title: title
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleNext = () => {
    saveListing();
  };

  const handleTitleChange = (e) => {
    const value = e.target.value;
    if (value.length <= charLimit) {
      setTitle(value);
    }
  };

  return (
    <div>
      <h4 className='ws-title'>Time to give your house a title!</h4>
      <p class="ws-desc">Keep it short and have fun—you can always update it later.</p>

      <div className='st1-desc-box'>
        <textarea
          id='listing-title'
          className='form-control mb-4'
          rows={4}
          value={title}
          onChange={handleTitleChange}
        />

        <div className='hint-box'>
          <p className='msg-txt'>
            {charLimit - (title?.length || 0)} characters available
          </p>
        </div>
      </div>

      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default ListingStep9;
