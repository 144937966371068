import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';

const ListingStep15 = ({ nextStep, prevStep, formData }) => {
 
  const { listingId } = formData;
  const [offerList, setOfferList] = useState([]);

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;
          try {
            const { offers = [] } = listingData;
            console.log('Fetched place options:', offers);
            setOfferList(offers);
          } catch (error) {
            toast.error('Error parsing listing data: ' + error.message);
            console.error('Parsing error:', error);
          }
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
  }, [listingId]); 

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const updatedPropertyItem = {
      offers: offerList
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleCheckboxChange = (id) => {
    setOfferList((prevOfferList) =>
      prevOfferList.map((offer) =>
        offer.id === id ? { ...offer, checked: !offer.checked } : offer
      )
    );
  };

  const handleInputChange = (id, value) => {
    setOfferList((prevOfferList) =>
      prevOfferList.map((offer) =>
        offer.id === id ? { ...offer, given_discount: value } : offer
      )
    );
  };

  const handleNext = () => {
    saveListing();
  };

  return (
    <div className='clss'>
      <h4 className='ws-title'>Add discounts</h4>
      <p className='ws-desc'>Earn double bookings & reviews with exciting offers.</p>

      <div className='st1-desc-box'>
        <div className='row'>

            {offerList.length > 0 ? (
            offerList.map((option) => (
                <div key={option.id} className='col-md-12 lb mb-3'>
                <label className='radio-check-label'>
                    <span>
                        <div className='dbpats'>
                            <div className='dbpimg'>
                                <img src={`/assets/img/icons/discount_${option.id}.svg`} alt='' className='img-fluid' />
                            </div>
                            <div className='dbpt'>
                                <input 
                                className='discount-input' 
                                name='offers' 
                                value={option.given_discount} 
                                disabled={!option.edit || !option.checked}
                                onChange={(e) => handleInputChange(option.id, e.target.value)}
                                />
                            </div>
                            <div className='dbds'>
                                <p>{option.title}</p>
                                <p>{option.desc}</p>
                        </div>
                        </div>
                        <div className='dbcb'>
                            <input 
                                type="checkbox" 
                                name="discounts" 
                                value="promotion" 
                                checked={option.checked === true}
                                onChange={() => handleCheckboxChange(option.id)}
                            />
                        </div>
                    </span>
                </label>
                </div>
            ))
            ) : (
            <p>No offer available</p>
            )}

          <div className='col-md-12'>
            <p className='hint-txt'>Only one discount will be applied per stay. Learn more</p>
          </div>

        </div>
      </div>

      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default ListingStep15;
