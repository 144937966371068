import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import ThemeWrapper from '../ThemeWrapper ';

const ListingStep7 = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const [amenities, setAmenities] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categoryId, setCategoryId] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getAmenities}`,
          data: formData,
        });

        if (response.data.status === 'ama') {
          setAmenities(response.data.ama_list);
          setLoading(false);
        } else {
          setError('An error occurred while fetching data.');
        }
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          setCategoryId(response.data.updated_property_item.category_id);
          const selectedIds = response.data.updated_property_item.amanities.map((amenity) => amenity.ama_id);
          setSelectedAmenities(selectedIds);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchCategories();
    fetchListingData();
  }, []);

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append(
      'data',
      JSON.stringify({
        amanities: selectedAmenities.map((ama_id) => ({ ama_id, stay_listing_id: listingId })),
      })
    );
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleNext = () => {
    saveListing();
  };

  const handleAmenityChange = (e) => {
    const { value } = e.target;
    if (selectedAmenities.includes(parseInt(value))) {
      setSelectedAmenities(selectedAmenities.filter((id) => id !== parseInt(value)));
    } else {
      setSelectedAmenities([...selectedAmenities, parseInt(value)]);
    }
  };

  const renderCategory = (id, title, list) => (
    <div className='gss-box' key={title}>
      <div className='gss-txt-img-box'>
        <img src={`/assets/img/icons/amanity_${id}.svg`} alt='' className='gss-img' />
        <h4>{title}</h4>
      </div>
      <div className='cat-box'>
        {list.length > 0 ? (
          list.map((item) => (
            <label className='cat-label' key={item.ama_id}>
              <input
                type="checkbox"
                value={item.ama_id}
                checked={selectedAmenities.includes(item.ama_id)}
                onChange={handleAmenityChange}
              />
              <span>
                <img
                  src={
                    item.ama_image
                      ? item.ama_image_url
                      : 'https://stcontent.blr1.digitaloceanspaces.com/stays/1722843850_stct.png'
                  }
                  alt={item.ama_name ? item.ama_name : 'Untitled Listing'}
                  className='cat-img'
                />
                <p>{item.ama_name}</p>
              </span>
            </label>
          ))
        ) : (
          <p>No items found in {title}</p>
        )}
      </div>
    </div>
  );

  return (
    <ThemeWrapper categoryId={categoryId}>
      <div>
      <h4 className='ws-title'>What your Wire Stay Offers?</h4>
      <p className='ws-desc'>You can always add more post publishing the listing. </p>
      {loading ? <p>Loading...</p> : null}
      {error ? <p>{error}</p> : null}
      
      <div className='st1-desc-box'>
        {amenities.length > 0 ? (
          <>
            {renderCategory(1, 'General', amenities[0].list)}
            {renderCategory(2, 'Standout', amenities[2].list)}
            {renderCategory(3, 'Safety', amenities[4].list)}
          </>
        ) : (
          <p>No data found</p>
        )}
      </div>

      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Next</button>
      </div>
      </div>
    </ThemeWrapper>
  );
};

export default ListingStep7;
