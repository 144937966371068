import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import GetReceipt from '../components/GetReceipt';

const BookingDetails = () => {
    const { bookingId, categoryId } = useParams();
    const [bookingData, setBookingData] = useState([]);
    const [filteredBooking, setFilteredBooking] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [popupUrl, setPopupUrl] = useState(null);

    const openPopup = (url) => {
        setPopupUrl(url);
    };

    const closePopup = () => {
        setPopupUrl(null);
    };
    
    useEffect(() => {
        const fetchBookingDetails = async () => {
            setLoading(true);
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('category', categoryId);
    
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.getMyBooking}`,
                    data: formData
                });
    
                if (response.data && response.data.items.length > 0) {
                    setBookingData(response.data.items);
                    const booking = response.data.items.find(
                        (item) => item.stay_booking_id === Number(bookingId)
                    );
                    setFilteredBooking(booking || null);
                } else {
                    setError('No booking data found for this category.');
                }
            } catch (err) {
                setError('An error occurred while fetching booking data.');
            } finally {
                setLoading(false);
            }
        };

        fetchBookingDetails();
    }, [categoryId, bookingId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>No booking found for the provided ID.</p>;
    }

    return (
        <div>
            <Helmet>
                <title>Booking Details</title>
                <meta name="description" content="" />
            </Helmet>

            <div className='booking-detail-section'>
                {filteredBooking && (
                    <>
                        <h4 className='bd-title'>{filteredBooking.listing_title}, {filteredBooking.city_town}</h4>
                        <div className='category-img-section' style={{border:'2px solid #A9A9A9'}}>
                            <div className='ciso'>
                                <img src={filteredBooking.cover_photo} alt='' className='img-fluid' />
                            </div>
                            <div className='cist'>
                                <img style={{height:'170px'}} src='https://stcontent.blr1.digitaloceanspaces.com/stays/1729827507_stct.png' alt='' className='img-fluid' />
                            </div>
                        </div>
                        <span className='b-line'></span>

                        <div>
                            <h4 className='bd-title'>Reservation Details</h4>
                            <div className='bd-box'>
                                <strong>Guest info</strong>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="" className="bd-icon" />
                                    <p>{filteredBooking.customer_name}</p>
                                </div>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="" className="bd-icon" />
                                    <p>{filteredBooking.customer_phone}</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='bd-box'>
                                <strong>Who's coming</strong>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="" className="bd-icon" />
                                    <p>1 guest</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='bd-box'>
                                <strong>Confrimation code</strong>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="" className="bd-icon" />
                                    <p>{filteredBooking.confirmation_code}</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='doc-box' onClick={() => openPopup(filteredBooking.stay_booking_receipt_url)}>
                                <div>
                                    <span>Get receipt</span>
                                </div>
                                <div>
                                    <i class="fas fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>

                        <span className='b-line'></span>

                        <div>
                            <h4 className='bd-title'>Payment info</h4>
                            <div className='bd-box'>
                                <strong>Amount paid</strong>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="" className="bd-icon" />
                                    <p>{filteredBooking.priceItem.currency_symbol} {filteredBooking.priceItem.total}</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='doc-box' onClick={() => openPopup(filteredBooking.stay_booking_payment_receipt_url)}>
                                <div>
                                    <span>Get receipt</span>
                                </div>
                                <div>
                                    <i class="fas fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>

                        <span className='b-line'></span>

                        <div>
                            <h4 className='bd-title'>Cancellation policy</h4>
                            <p> This reservation is non-refundable.</p>
                        </div>
                    </>
                )}
            </div>

            <GetReceipt url={popupUrl} onClose={closePopup} />

        </div>
    );
};

export default BookingDetails;
