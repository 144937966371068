import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import Chat from './Chat';

function Messages() {
    const [chatRequests, setChatRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedChat, setSelectedChat] = useState(null);

    useEffect(() => {
        const fetchMyRequests = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('sender_type', 2);
            setLoading(true);
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                    url: `${params.baseURL}${params.endpoints.chatMyRequests}`,
                    data: formData,
                });

                if (response.data.items && Array.isArray(response.data.items)) {
                    setChatRequests(response.data.items);
                    setLoading(false);
                } else {
                    toast.error('An error occurred while fetching data.');
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        fetchMyRequests();
    }, []);

    const openChat = (chatData) => {
        setSelectedChat(chatData);
    };

    const closeChat = () => {
        setSelectedChat(null);
    };

    return (
        <div>
            <Helmet>
                <title>Messages</title>
                <meta name="description" content="" />
            </Helmet>

            <div className="row">
                {/* Chat List Section */}
                <div className="col-md-4 message-list-part">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div className="messages-list-section">
                            {chatRequests.length > 0 ? (
                                chatRequests.map((list) => (
                                    <div className="message-list-box" key={list.request_id}>
                                        <div className="mrib">
                                            <img
                                                className="mru-pic"
                                                src={list.request_from_pic !== null ? list.request_from_pic : '/assets/img/ws_user_icon.png'}
                                                alt=""
                                            />
                                        </div>
                                        <div className="mrncb">
                                            <p>{list.request_from_name}</p>
                                            <div>
                                                <img
                                                    className="chat-icon"
                                                    src="/assets/img/icons/chat_icon.svg"
                                                    alt=""
                                                    onClick={() => openChat(list)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="empty-section ems">
                                    <p>Your inbox is on a coffee break – no messages from guests just yet!</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {/* Chat Content Section */}
                <div className="col-md-8">
                    {selectedChat ? (
                        <Chat chatData={selectedChat} onClose={closeChat} />
                    ) : (
                        <div className="empty-chat-section ems">
                            <p>Select a message to start a chat.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Messages;
