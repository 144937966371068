import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';

const ListingStep5 = ({ nextStep, prevStep, formData }) => {
  const min = 1;
  const max = 10;

  const { listingId } = formData;
  const [guests, setGuests] = useState(1);
  const [beds, setBeds] = useState(1);
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);

  const handleDecrement = (value, setValue) => {
    if (value > min) {
      setValue(value - 1);
    }
  };

  const handleIncrement = (value, setValue) => {
    if (value < max) {
      setValue(value + 1);
    }
  };

  const handleInputChange = (e, setValue) => {
    const newValue = Number(e.target.value);
    if (newValue >= min && newValue <= max) {
      setValue(newValue);
    }
  };

  useEffect(() => {
  
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          setGuests(response.data.updated_property_item.no_of_guests || 1);
          setBeds(response.data.updated_property_item.no_of_beds || 1);
          setBathrooms(response.data.updated_property_item.no_of_bathrooms || 1);
          setBedrooms(response.data.updated_property_item.no_of_bedrooms || 1);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
  }, []);

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const updatedPropertyItem = {
      no_of_guests: guests,
      no_of_beds: beds,
      no_of_bedrooms: bedrooms,
      no_of_bathrooms: bathrooms,
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleNext = () => {
    saveListing();
  };

  return (
    <div>
      <h4 className='ws-title'>Provide basic details here</h4>
      <p class="ws-desc">More specifics can be added later.</p>

      <div className='st1-desc-box'>
        <div className='gbbbb'>
          <div className='gbbb-icon-box'>
            <img src={'/assets/img/icons/guest_icon.svg'} alt='' className='img-fluid' />
            <label className='st1-label'>Guests</label>
          </div>
          <div className="input-number-container">
            <span className="input-number-decrement" onClick={() => handleDecrement(guests, setGuests)}>–</span>
            <input
              className="input-number"
              type="number"
              value={guests}
              onChange={(e) => handleInputChange(e, setGuests)}
              min={min}
              max={max}
              readOnly={true}
            />
            <span className="input-number-increment" onClick={() => handleIncrement(guests, setGuests)}>+</span>
          </div>
        </div>

        <div className='gbbbb'>
          <div className='gbbb-icon-box'>
            <img src={'/assets/img/icons/bed_icon.svg'} alt='' className='img-fluid' />
            <label className='st1-label'>Beds</label>
          </div>
          <div className="input-number-container">
            <span className="input-number-decrement" onClick={() => handleDecrement(beds, setBeds)}>–</span>
            <input
              className="input-number"
              type="number"
              value={beds}
              onChange={(e) => handleInputChange(e, setBeds)}
              min={min}
              max={max}
              readOnly={true}
            />
            <span className="input-number-increment" onClick={() => handleIncrement(beds, setBeds)}>+</span>
          </div>
        </div>

        <div className='gbbbb'>
          <div className='gbbb-icon-box'>
            <img src={'/assets/img/icons/bedroom_icon.svg'} alt='' className='img-fluid' />
            <label className='st1-label'>Bedrooms</label>
          </div>
          <div className="input-number-container">
            <span className="input-number-decrement" onClick={() => handleDecrement(bedrooms, setBedrooms)}>–</span>
            <input
              className="input-number"
              type="number"
              value={bedrooms}
              onChange={(e) => handleInputChange(e, setBedrooms)}
              min={min}
              max={max}
              readOnly={true}
            />
            <span className="input-number-increment" onClick={() => handleIncrement(bedrooms, setBedrooms)}>+</span>
          </div>
        </div>

        <div className='gbbbb'>
          <div className='gbbb-icon-box'>
            <img src={'/assets/img/icons/bathroom_icon.svg'} alt='' className='img-fluid' />
            <label className='st1-label'>Bathrooms</label>
          </div>
          <div className="input-number-container">
            <span className="input-number-decrement" onClick={() => handleDecrement(bathrooms, setBathrooms)}>–</span>
            <input
              className="input-number"
              type="number"
              value={bathrooms}
              onChange={(e) => handleInputChange(e, setBathrooms)}
              min={min}
              max={max}
              readOnly={true}
            />
            <span className="input-number-increment" onClick={() => handleIncrement(bathrooms, setBathrooms)}>+</span>
          </div>
        </div>
      </div>

      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default ListingStep5;
