import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2wJWK-WMBFuk5xnKbjPi-EcCl0KecqrQ",
  authDomain: "wire-stay.firebaseapp.com",
  databaseURL: "https://wire-stay-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "wire-stay",
  storageBucket: "wire-stay.firebasestorage.app",
  messagingSenderId: "482517957206",
  appId: "1:482517957206:web:adbf93eb7a8782d98219f7",
  measurementId: "G-7L0L4T2SME"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//const db = getFirestore(app);
const db = getDatabase(app);

export { db };