const TTL = 7 * 24 * 60 * 60 * 1000;

export const setWithExpiry = (key, value) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + TTL,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const getItemFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  if (item) {
    const data = JSON.parse(item);
    return JSON.parse(data.value);
  }
  return null;
};

export const getDataFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  if (item) {
    try {
      const data = JSON.parse(item);
      return data.value || data;
    } catch (error) {
      console.error('Failed to parse item from localStorage:', error);
      return null;
    }
  }
  return null;
};