import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';
import PendingDocumentsPopup from '../components/PendingDocumentsPopup';

function Home() {

    const [tripCatgeory, setTripCategory] = useState([]);
    const [selectedCategoryData, setSelectedCategoryData] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [pendingListing, setPendingListing] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    useEffect(() => {
        const fetchTripCategory = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.getTripCategory}`,
                    data: formData
                });
            
                if (response.data.status === 'category') {
                    setTripCategory(response.data.trip_cat_list);
                    setSelectedCategory(response.data.trip_cat_list[0]?.category_id);
                    setLoading(false);
                } else {
                    setError('An error occurred while fetching data.');
                }
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        const fetchPendingListing = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.getPendingListings}`,
                    data: formData
                });
            
                if (response.data.items && Array.isArray(response.data.items)) {
                    setPendingListing(response.data.items);
                    setLoading(false);
                } else {
                    setError('An error occurred while fetching data.');
                }
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchTripCategory();
        fetchPendingListing();
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            handleCategoryChange(selectedCategory);
        }
    }, [selectedCategory]);

    const handlePendingListClick = (data) => {
        setSelectedDocuments(data);
        setShowPopup(true);
    };

    const handleCategoryChange = async (categoryId) => {
        setSelectedCategory(categoryId);
        setCategoryLoading(true);
        const hostData = getItemFromLocalStorage('hostProfile');
        let formData = new FormData();
        formData.append('token', hostData.host_access_token);
        formData.append('category', categoryId);

        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.getMyBooking}`,
                data: formData
            });

            if (response.data && response.data.items.length > 0) {
                setSelectedCategoryData(response.data.items);
            } else {
                setSelectedCategoryData([]);
            }
        } catch (err) {
            setError('An error occurred while fetching category data.');
            setSelectedCategoryData([]);
        } finally {
            setCategoryLoading(false);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading category: {error.message}</p>;
    }

    return (
        <div>
          <Helmet>
            <title>Dashboard</title>
            <meta name="description" content="" />
          </Helmet>

          <div className='pending-listing-box'>
            {pendingListing.length > 0 ? (
                <div className='plbald'>
                    {pendingListing.map((data) => (
                        <div className='pldata-box' key={data.stay_listing_id} onClick={() => handlePendingListClick(data)}>
                            <div className='pldata-info-box'>
                                <div className='category-img-section'>
                                    <img className='img-fluid ciso'  
                                        src={data.cover_photo ? data.cover_photo : '/assets/img/default_listing.jpg'}
                                        alt='' 
                                    />
                                    <img src='https://stcontent.blr1.digitaloceanspaces.com/stays/1729827507_stct.png' alt='' className='cist' />
                                </div>
                                <div className='pltxt-box'>
                                    <div className='cat-pointer'>
                                        <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                                        <span>{data.listing_title && data.listing_title.trim() ? data.listing_title.trim() : 'Ghost Host'}</span>
                                    </div>
                                    <div className='cat-pointer'>
                                        <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                                        <span><strong>Verify Owner's identity</strong></span>
                                    </div>
                                    <div className='catp-box'>
                                        <div className='cat-pointer mb-0'>
                                            <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                                            <span className='plpvrtb'>Pending: Verification Required</span>
                                        </div>
                                        <div>
                                            <i class="fas fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className='pldata-btn-block'>
                                <button className='btn btn-black'>Start Verification</button>
                            </div>*/}
                        </div>
                    ))}
                </div>
            ) : (
                <></>
            )}
          </div>

        <div className='filter-box'>
          {tripCatgeory.length > 0 ? (
            <div className='filter-booking-box'>
                {tripCatgeory.map((category) => (
                    <label class="filter-booking" key={category.category_id}>
                        <input type="radio" name="bookingData" id="booking_data" 
                            class="filter__radio input" 
                            value={category.category_id} 
                            checked={selectedCategory === category.category_id}
                            onChange={() => handleCategoryChange(category.category_id)}
                        />
                        <span>{category.category_name}</span>
                    </label>
                ))}
            </div>
            ) : (
                <p>No catgeory found</p>
            )}
          </div>

          <div className="category-data-section">
            {categoryLoading ? (
                <p></p>
            ) : selectedCategoryData && selectedCategoryData.length > 0 ? (
                <div className='category-section'>
                    {selectedCategoryData.map((item, index) => (
                        <a href={`/booking-details/${item.stay_booking_id}/${selectedCategory}`} className='category-data' key={index}>
                            <div className='category-img-section'>
                                <img src={item.cover_photo} alt='' className='ciso' />
                                <img src='https://stcontent.blr1.digitaloceanspaces.com/stays/1729827507_stct.png' alt='' className='cist' />
                            </div>
                            <div className='category-data-header cat-pointer'>
                                <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                                <span>{item.confirmation_code}</span>
                            </div>
                            <div className='cat-pointer'>
                                <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                                <span>{item.listing_title}</span>
                            </div>
                            <div className='cat-pointer'>
                                <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                                <div>
                                    <span>{item.checkin_date_short}</span> - <span>{item.checkout_date_short}</span>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='category-user-box'>
                                <div className='category-utxt-box'>
                                    <div>
                                        <img src={'/assets/img/ws_user_icon.png'} alt="Profile" className="user-circle" />
                                    </div>
                                    <div>
                                        <p>{item.customer_name}</p>
                                    </div>
                                </div>
                                <div>
                                    <i class="fas fa-chevron-right"></i>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            ) : (
                <div className='nbfcb'>
                    {selectedCategory === 4 && (
                        <p>The approval queue is taking a nap – no requests knocking yet!</p>
                    )}
                    {selectedCategory === 1 && (
                        <p>Your booked section is as free as a bird – nothing locked in for now.</p>
                    )}
                    {selectedCategory === 2 && (
                        <p>Looks like your completed bookings are on vacation – nothing to show here!</p>
                    )}
                    {selectedCategory === 3 && (
                        <p>No cancellations in sight – your record is squeaky clean!</p>
                    )}
                </div>
            )}
          </div>

          <PendingDocumentsPopup 
              show={showPopup} 
              onClose={() => setShowPopup(false)} 
              data={selectedDocuments} 
          />

        </div>
    );
}

export default Home;