import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import ListingStep1 from '../components/ListingStep1';
import ListingStep2 from '../components/ListingStep2';
import ListingStep3 from '../components/ListingStep3';
import ListingStep4 from '../components/ListingStep4';
import ListingStep5 from '../components/ListingStep5';
import ListingStep6 from '../components/ListingStep6';
import ListingStep7 from '../components/ListingStep7';
import ListingStep8 from '../components/ListingStep8';
import ListingStep9 from '../components/ListingStep9';
import ListingStep10 from '../components/ListingStep10';
import ListingStep11 from '../components/ListingStep11';
import ListingStep12 from '../components/ListingStep12';
import ListingStep13 from '../components/ListingStep13';
import ListingStep14 from '../components/ListingStep14';
import ListingStep15 from '../components/ListingStep15';
import ListingStep16 from '../components/ListingStep16';
import ListingStep17 from '../components/ListingStep17';
import ListingStep18 from '../components/ListingStep18';

const CreateListing = () => {
  const [step, setStep] = useState(1);
  const { listingId } = useParams();
  const totalSteps = 18;
  const [formData, setFormData] = useState({
    listingId: listingId || null,
  }); 

  const nextStep = () => setStep(prevStep => prevStep + 1);
  const prevStep = () => setStep(prevStep => prevStep - 1);

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const progressPercentage = (step / totalSteps) * 100;

  useEffect(() => {
    console.log('Current step:', step);
    console.log('Form data:', formData);
  }, [step, formData]);

  const ProgressBar = () => (
    <div className='progress-bar'>
      <div style={{ width: '100%', backgroundColor: '#e0e0e0' }}>
        <div
          style={{
            width: `${progressPercentage}%`,
            backgroundColor: '#000',
            height: '10px',
            transition: 'width 0.3s ease-in-out',
          }}
        />
      </div>
    </div>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return <ListingStep1 nextStep={nextStep} />;
      case 2:
        return <ListingStep2 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 3:
        return <ListingStep3 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 4:
        return <ListingStep4 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 5:
        return <ListingStep5 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 6:
        return <ListingStep6 nextStep={nextStep} prevStep={prevStep} />;
      case 7:
        return <ListingStep7 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 8:
        return <ListingStep8 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 9:
        return <ListingStep9 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 10:
        return <ListingStep10 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 11:
        return <ListingStep11 nextStep={nextStep} prevStep={prevStep} />;
      case 12:
        return <ListingStep12 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 13:
        return <ListingStep13 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 14:
        return <ListingStep14 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 15:
        return <ListingStep15 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 16:
        return <ListingStep16 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 17:
        return <ListingStep17 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      case 18:
        return <ListingStep18 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Helmet>
        <title>Create Listing</title>
        <meta name="description" content="" />
      </Helmet>

      <ProgressBar />
      {renderStep()}
    </div>
  );

};

export default CreateListing;
