import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import params from '../../config/Params';
import { getItemFromLocalStorage } from '../../utils/storageUtils';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Overview = () => {
  
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [affiliateNumber, setAffiliateNumber] = useState('');
  const navigate = useNavigate();

  const openAffiliateBox = () => {
    setDrawerOpen(true);
  };

  const skipAffiliate= async () => {
    setDrawerOpen(false);
    const captainData = getItemFromLocalStorage('hostProfile');

    let listData = new FormData();
    listData.append('token', captainData.host_access_token);
    listData.append('category_id', 35);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.createListing}`,
        data: listData
      });

      if (response.data.status === 'done') {
        const newListingId = response.data.listing_id;
        const propertyItem = response.data.property_item;
        delete propertyItem.cover_photo;
        localStorage.setItem(`property_item_${newListingId}`, JSON.stringify(propertyItem));
        navigate(`/hotel/category/${newListingId}`);
      }
    } catch (err) {
      toast.error('An error occurred while saving data.');
    }
  }

  const checkAffiliate = async (affiliateNumber) => {
    const captainData = getItemFromLocalStorage('hostProfile');

    let formData = new FormData();
    formData.append('token', captainData.host_access_token);
    formData.append('m', affiliateNumber);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.checkAffiliate}`,
        data: formData
      });

      if (response.data.status === 'valid') {
        setDrawerOpen(false);

        let listData = new FormData();
        listData.append('token', captainData.host_access_token);
        listData.append('category_id', 35);
        listData.append('affiliate_id', response.data.affiliate_id);

        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            url: `${params.baseURL}${params.endpoints.createListing}`,
            data: listData
          });
          if (response.data.status === 'done') {
            const newListingId = response.data.listing_id;
            const propertyItem = response.data.property_item;
            delete propertyItem.cover_photo;
            delete propertyItem.share_url;
            localStorage.setItem(`property_item_${newListingId}`, JSON.stringify(propertyItem));
            navigate(`/hotel/category/${newListingId}`);
          }
        } catch (err) {
          toast.error('An error occurred while saving data.');
        }
      } else if (response.data.status === 'error') {
        toast.error(response.data.list);
      } else {
        toast.error('Error in saving data.');
      }
    } catch (err) {
      toast.error('An error occurred while saving data.');
    }
  }

  return (
    <div>
      <Helmet>
        <title>Create Listing</title>
        <meta name="description" content="" />
      </Helmet>
      <div className='row align-items-center'>
        <div className='col-md-6'>
          <h4 className='ov-title'>Get your first booking on Wire Stay!</h4>
        </div>
        <div className='col-md-6'>
          <div className='cl-overview'>

            <div className='overview-point-box'>
                <div className='overview-point-box-number'>
                   <span>1.</span> 
                </div>
                <div className='overview-point-box-content'>
                  <p className='ovp-title'>Share your place details</p>
                  <p className='ovp-desc'>Tell us about your property's location and guest capacity.</p>
                </div>               
                <div className='overview-image-box'>
                  <img src={'https://a0.muscache.com/4ea/air/v2/pictures/da2e1a40-a92b-449e-8575-d8208cc5d409.jpg'} alt='' />
                </div>
            </div>

            <div className='overview-point-box'>
                <div className='overview-point-box-number'>
                   <span>2.</span> 
                </div>
                <div className='overview-point-box-content'>
                  <p className='ovp-title'>Highlight your space</p>
                  <p className='ovp-desc'>Upload 5+ photos, write a compelling title and description.</p>
                </div>             
                <div className='overview-image-box'>
                  <img src={'https://a0.muscache.com/4ea/air/v2/pictures/da2e1a40-a92b-449e-8575-d8208cc5d409.jpg'} alt='' />
                </div>
            </div>

            <div className='overview-point-box'>
                <div className='overview-point-box-number'>
                   <span>3.</span> 
                </div>
                <div className='overview-point-box-content'>
                  <p className='ovp-title'>Finish and publish</p>
                  <p className='ovp-desc'>Set your starting price, guest preferences, and publish it.</p>
                </div>            
                <div className='overview-image-box'>
                  <img src={'https://a0.muscache.com/4ea/air/v2/pictures/da2e1a40-a92b-449e-8575-d8208cc5d409.jpg'} alt='' />
                </div>
            </div>

          </div>
        </div>
      </div>

      <div className='clp-footer' style={{justifyContent:'end'}}>
        <button className='btn btn-black' 
            onClick={() => openAffiliateBox()}
            style={{background:'red'}}
        >Get Started</button>
      </div>

      <Drawer
          anchor='bottom'
          className='drawer'
          open={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
      >
          <div className='drawer-content post-popup'>
              <div className='post-popup-box' style={{padding: '50px 10px', width: '500px', maxWidth: '500px'}}>
                <div>
                  <span className='drawer-close-btn' onClick={() => setDrawerOpen(false)}>
                    <img src={'/assets/img/icons/close_icon.svg'} className='w-100' alt='' />
                  </span>
                </div>
                  <div className='popup-title' style={{marginBottom: '1rem', fontWeight: '600' }}>AFFILIATION</div>
                  <div className='popup-options'>
                    <div>
                      <div className="form-floating">
                      <input type="text" id="coupon_code" className="form-control" value={affiliateNumber} onChange={(e) => setAffiliateNumber(e.target.value)} required />
                        <label htmlFor="coupon_code">Enter affiliate mobile number</label>
                      </div>
                    </div>
                    <div className='coupon-drawer-footer'>
                      <button className="btn btn-primary" onClick={() => skipAffiliate()}>Skip</button>
                      <button className="btn btn-success" onClick={() => checkAffiliate(affiliateNumber)}>Proceed</button>
                    </div>
                  </div>
              </div>
          </div>
      </Drawer>

    </div>
  )
}

export default Overview;
