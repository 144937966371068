import React from 'react';
import { Navigate } from 'react-router-dom';
import { getWithExpiry } from './utils/storageUtils';

const ProtectedRoute = ({ children }) => {
  const hostId = getWithExpiry('host_id');

  return hostId ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
