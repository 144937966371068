import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import ThemeWrapper from '../ThemeWrapper ';
import Lottie from "lottie-react";
import animationData from "../animation.json";

const ListingStep2 = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [propertyItem, setPropertyItem] = useState(null);
  const [categoryId, setCategoryId] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getCategories}`,
          data: formData,
        });
  
        if (response.data.status === 'category') {
          setCategories(response.data.property_cat_list);
          setLoading(false);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    };
  
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          setPropertyItem(response.data.updated_property_item);
          setCategoryId(String(response.data.updated_property_item.category_id));
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchCategories();
    fetchListingData();
  }, []);
  

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const categoryInfo = categories.find(cat => cat.category_id === parseInt(categoryId));
  
    const updatedPropertyItem = {
      category_id: categoryId,
      category_info: {
        category_id: categoryInfo.category_id,
        category_image: categoryInfo.category_image,
        category_image_url: categoryInfo.category_image_url,
        category_name: categoryInfo.category_name,
      }
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };
  
  const handleNext = () => {
    saveListing();
  };

  const handleCategoryChange = (e) => {
    console.log(categoryId);
    setCategoryId(e.target.value);
  };

  return (
    <ThemeWrapper categoryId={categoryId}>
      <div>
      <div className='row'>     
      <div className='col-md-4 listing-img-box'>
          <div>
            <img src={'/assets/img/listing/listing_side_img.gif'} alt="Listing Image" className="listing-image" />
          </div>
        </div>
        <div className='col-md-8 offset-md-4'>
          <h4 className='st1-title'>What’s Your Property Type?</h4>
          {loading ? <p>Loading...</p> : null}
          <div className='st1-desc-box'>
            {categories.length > 0 ? (
              <div className='cat-box'>
                {categories.map((data) => (
                  <label className='cat-label' key={data.category_id}>
                    <input
                      type="radio"
                      name="category"
                      value={data.category_id}
                      checked={categoryId === String(data.category_id)}
                      onChange={handleCategoryChange}
                    />
                    <span>
                      <img
                        src={data.category_image_url || 'https://stcontent.blr1.digitaloceanspaces.com/stays/1722843850_stct.png'}
                        alt={data.category_name || 'Untitled Listing'}
                        className='cat-img'
                      />
                      <p>{data.category_name}</p>
                    </span>
                  </label>
                ))}
              </div>
            ) : (
              <p>No data found</p>
            )}
          </div>

          <div className='clp-footer'>
            <button className='btn btn-blank' onClick={prevStep}>Back</button>
            <button className='btn btn-black' onClick={handleNext}>Next</button>
          </div>
        </div>
      </div>
      </div>
    </ThemeWrapper>
  );
};

export default ListingStep2;
