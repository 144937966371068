import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from '../components/Loading';
import { Helmet } from 'react-helmet';

function Notifications() {
    const [activeTab, setActiveTab] = useState(1); 
    const [loading, setLoading] = useState(true);
    const [followList, setFollowList] = useState([]);
    const [captainCategory, setCaptainCategory] = useState([]);

    const hostData = getItemFromLocalStorage('hostProfile');
    const token = hostData.host_access_token;
    const hostId = getItemFromLocalStorage('host_id');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const formData = new FormData();
            formData.append('listing_id', hostId);
            formData.append('token', token);
            formData.append('type', 'host');
            formData.append('category', activeTab);

            const url = `${params.baseURL}${params.endpoints.notificationList}`;

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: url,
                    data: formData
                });
                if (response.data.items && Array.isArray(response.data.items)) {
                    setFollowList(response.data.items);
                } else {
                    console.log('Data not Found.');
                }
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        };

        const getCaptainCategory = async () => {
            const formData = new FormData();
            formData.append('token', token);
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.notificationCategory}`,
                    data: formData
                });
                if (response.data.status === 'found') {
                    setCaptainCategory(response.data.notification_category);
                } else {
                    console.log('Data not Found.');
                }
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchData();
        getCaptainCategory();
    }, [activeTab, hostId, token]);

    return (
        <div>
            <Helmet>
                <title>Notification</title>
            </Helmet>
            <div className="ntab-buttons d-flex">
                {captainCategory.map((list) => (
                    <button 
                        key={list.id}
                        onClick={() => handleTabClick(list.id)} 
                        className={activeTab === list.id ? 'tb-btn tb-active' : 'tb-btn'}
                    >
                        {list.title}
                    </button>
                ))}
            </div>
            <span className='side-line'></span>
            <div className="ntab-content">
                {loading ? (
                    <Loading />
                ) : (
                    <div className="tab-content-item">
                        <div>
                            {followList.length > 0 ? (
                                <>
                                    {followList.map((data) => (
                                        <div className='follow-followes-list' key={data.notification_id}>
                                            <div className='fflb'>
                                                <div className='ffl-img'>
                                                    <img 
                                                        src={data.sender_info?.image ? data.sender_info.image : '/assets/img/icons/default_user.svg'}
                                                        alt="Wire Stay User" 
                                                        className='ffl-sender-img'
                                                    />
                                                    {data.category_icon_url && (
                                                        <img 
                                                            src={'/assets/img/icons/ffl-category-icon.png'}
                                                            alt="Wire Stay User" 
                                                            className='ffl-category-icon'
                                                        />
                                                    )}
                                                </div>
                                                <div className='ffl-txt'>
                                                    <p className='noti-title'>{data.message_title}</p>
                                                    <p className='noti-desc'>{data.message}</p>
                                                    <p className='noti-time'>{data.created_time_as_text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div className='empty-section text-center'>
                                    {activeTab === 1 && (
                                        <p>Looks like there are no new bookings... yet! Check back soon!</p>
                                    )}
                                    {activeTab === 2 && (
                                        <p>No cancellations here... let’s keep it that way!</p>
                                    )}
                                    {activeTab === 4 && (
                                        <p>Nothing to report... stay tuned for updates!</p>
                                    )}
                                    {/*<img src={'/assets/img/icons/no-notification.png'} alt='Wire Stay' />*/}
                                </div>
                            )}
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
}

export default Notifications;
