const GetReceipt = ({ url, onClose }) => {
    if (!url) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="grp-close" onClick={onClose}>
                    <img src={'/assets/img/icons/close_icon.svg'} alt="" />
                </button>
                <iframe src={url} title="Receipt" className="popup-iframe" />
            </div>
        </div>
    );
};

export default GetReceipt;
