import React from 'react';

const ListingStep1 = ({ nextStep }) => {
  return (
    <div>
      <div className='row align-items-center'>
        <div className='col-md-6'>
          <p className='st1'>Step 1:</p>
          <h4 className='st1-title'>Describe your place</h4>
          
          <div className='st1-desc-box'>
            <p>Specify property type, booking options (entire place or room), location, and guest capacity.</p>
          </div>
        </div>
        <div className='col-md-6 st1-img'>
          <video className='step-img' autoPlay muted playsInline>
            <source src="/assets/video/wirestay.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className='clp-footer'>
        <button className='btn btn-blank'>Back</button>
        <button className='btn btn-black' onClick={nextStep}>Next</button>
      </div>
    </div>
  );
};

export default ListingStep1;
