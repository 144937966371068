import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ThemeWrapper from '../ThemeWrapper ';

const MyCalendar = () => {
    const [calendar, setCalendar] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCalendar = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    url: `${params.baseURL}${params.endpoints.getMyCalendar}`,
                    data: formData,
                });

                if (response.data.items && Array.isArray(response.data.items)) {
                    setCalendar(response.data.items);
                    setLoading(false);
                } else {
                    setError('An error occurred while fetching data.');
                }
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchCalendar();
    }, []);

    const openCalendarPage = (listing) => {
        navigate(`/rooms-price/${listing.stay_listing_id}`);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading listings: {error.message}</p>;
    }

    return (
        <div>
            <Helmet>
                <title>Calendar</title>
                <meta name='description' content='' />
            </Helmet>

            <h1 className='ws-title'>Calendar</h1>
            <p className='ws-desc'>Once your listing is live, you can manage your calendar right here, set and edit prices or block the night right here.</p>

            {calendar.length > 0 ? (
                <div className='row'>
                    {calendar.map((data) => (
                        <div className='col-md-12 lb mb-3' key={data.stay_listing_id}>
                            <ThemeWrapper categoryId={data.category_id}>
                                <a href='javascript:void(0);' className='mcldb' onClick={() => openCalendarPage(data)}>
                                    <div className='mcaldbox'>
                                        <div className='mcd-image'>
                                            <img
                                                src={data.cover_photo ? data.cover_photo : '/assets/img/default_listing.jpg'}
                                                alt={data.listing_title ? data.listing_title : 'Untitled Listing'}
                                            />
                                        </div>
                                        <div className='mcd-title'>
                                            <p>{data.listing_title ? data.listing_title : ''}</p>
                                        </div>
                                    </div>
                                    <div className='cal-img'>
                                        <i className='far fa-calendar-alt'></i>
                                    </div>
                                </a>
                            </ThemeWrapper>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No data found</p>
            )}
        </div>
    );
};

export default MyCalendar;
