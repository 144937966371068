import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import Drawer from '@mui/material/Drawer';

const ListingStep17 = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const [stdPolicy, setStdPolicy] = useState([]);
  const [lngPolicy, setLngPolicy] = useState([]);
  const [selectedStdPolicy, setSelectedStdPolicy] = useState(null);
  const [selectedLngPolicy, setSelectedLngPolicy] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isStandard, setIsStandard] = useState(true);
  const [nonrefundable, setNonrefundable] = useState(false);

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });

        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;
          try {
            const { std_cp_list = [], lng_cp_list = [] } = listingData;
            setStdPolicy(std_cp_list);
            setLngPolicy(lng_cp_list);
            // Set default selected policies
            setSelectedStdPolicy(std_cp_list[0]?.id);
            setSelectedLngPolicy(lng_cp_list[0]?.id);
          } catch (error) {
            toast.error('Error parsing listing data: ' + error.message);
            console.error('Parsing error:', error);
          }
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchListingData();
  }, [listingId]);

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();

    const updatedPropertyItem = {
      std_c_policy: selectedStdPolicy,
      c_nonrefundable: nonrefundable,
      long_c_policy: selectedLngPolicy,
    };

    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem));
    catData.append('ts', ts);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });

      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleNext = () => {
    saveListing();
  };

  const openDrawer = (isStandardPolicy) => {
    setIsStandard(isStandardPolicy);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handlePolicyChange = (id) => {
    if (isStandard) {
      setSelectedStdPolicy(id);
    } else {
      setSelectedLngPolicy(id);
    }
    closeDrawer(); // Close the drawer after selection
  };

  // Determine which policy to display in the main view
  const currentStdPolicy = stdPolicy.find(policy => policy.id === selectedStdPolicy) || stdPolicy[0];
  const currentLngPolicy = lngPolicy.find(policy => policy.id === selectedLngPolicy) || lngPolicy[0];

  return (
    <div>
      <div className='clp-header'>
        <h4 className='ws-title'>Cancellation policy</h4>
        <p className='ws-desc'></p>
      </div>

      <div className='st1-desc-box'>
        <div className='row'>
          <div className='col-md-12'>
            <p className='m-0'><strong>Standard policy</strong></p>
            <p>Applies to any stay under 28 nights.</p>
          </div>

          {currentStdPolicy && (
            <div className='col-md-12'>
              <div className='policy-box' onClick={() => openDrawer(true)}>
                <div className='policy-box-header'>
                  <p>{currentStdPolicy.title}</p>
                  <p><i className="fas fa-pencil-alt"></i></p>
                </div>
                <p>{currentStdPolicy.desc}</p>
              </div>
            </div>
          )}

          <div className='col-md-12'>
            <div className='policy-box'>
              <div className='policy-box-header'>
                <p>Non-refundable</p>
                <p>
                  <input 
                    className="switch" 
                    type="checkbox" 
                    checked={nonrefundable}
                    onChange={(e) => setNonrefundable(e.target.checked)}
                  />
                </p>
              </div>
              <p>In addition to Moderate, offer a non-refundable option guests pay 10% less, but you keep your payout no matter when they cancel.</p>
            </div>
          </div>

          <div className='col-md-12'>
            <p className='m-0'><strong>Long-term stay policy</strong></p>
            <p>Applies to any stays 28 nights or longer.</p>
          </div>

          {currentLngPolicy && (
            <div className='col-md-12'>
              <div className='policy-box' onClick={() => openDrawer(false)}>
                <div className='policy-box-header'>
                  <p>{currentLngPolicy.title}</p>
                  <p><i className="fas fa-pencil-alt"></i></p>
                </div>
                <p>{currentLngPolicy.desc}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Next</button>
      </div>

      <Drawer anchor="bottom" open={drawerOpen} onClose={closeDrawer}>
        <div style={{ padding: '20px' }}>
          <h4 style={{marginBottom:'2rem'}}>{isStandard ? "Select Standard Policy" : "Select Long-Term Policy"}</h4>
          {isStandard 
            ? stdPolicy.map((policy) => (
              <div className='drawer-policy-box' key={policy.id}>
                <label className='policy-radio-input'>
                  <div className='policy-radio-txt-box'>
                    <img src='/assets/img/icons/policy_icon.svg' alt='' />
                    <span>
                      <div>{policy.title}</div>
                      <div>{policy.desc}</div>
                    </span>
                  </div>
                  <div class="circle"></div> 
                  <input
                    type="radio"
                    value={policy.id}
                    checked={selectedStdPolicy === policy.id}
                    onChange={() => handlePolicyChange(policy.id)}
                  />
                </label>
              </div>
            )) 
            : lngPolicy.map((policy) => (
              <div className='drawer-policy-box' key={policy.id}>
                <label className='policy-radio-input'>
                  <div className='policy-radio-txt-box'>
                    <img src='/assets/img/icons/policy_icon.svg' alt='' />
                    <span>
                      <div>{policy.title}</div>
                      <div>{policy.desc}</div>
                    </span>
                  </div>
                  <div class="circle"></div> 
                  <input
                    type="radio"
                    value={policy.id}
                    checked={selectedLngPolicy === policy.id}
                    onChange={() => handlePolicyChange(policy.id)}
                  />
                </label>
              </div>
            ))}
        </div>
      </Drawer>
    </div>
  );
};

export default ListingStep17;
