import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../../../config/Params';
import { getItemFromLocalStorage } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';

const Policies = ({ formData, nextStep, prevStep }) => {
  const { listingId } = formData;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [description, setDescription] = useState('');
  const [charLimit] = useState(3000);
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const [cancellationPolicy, setCancellationPolicy] = useState(1);
  const [unmarriedCouplesAllowed, setUnmarriedCouplesAllowed] = useState(2);
  const [maleOnlyGroupsAllowed, setMaleOnlyGroupsAllowed] = useState(2);
  const [ageRestriction, setAgeRestriction] = useState(2);
  const [acceptableIdentityProofs, setAcceptableIdentityProofs] = useState([]);
  const [sameCityIdAllowed, setSameCityIdAllowed] = useState(2);
  const [smokingAllowed, setSmokingAllowed] = useState(2);
  const [partiesAllowed, setPartiesAllowed] = useState(2);
  const [visitorAccessAllowed, setVisitorAccessAllowed] = useState(2);
  const [wheelchairAccessible, setWheelchairAccessible] = useState(2);
  const [petAllowed, setPetAllowed] = useState(2);
  const [propertyPet, setPropertyPet] = useState(2);
  const [flexibleCheckin, setFlexibleCheckin] = useState(2);
  const [extraAdultBed, setExtraAdultBed] = useState(2);
  const [extraKidsBed, setExtraKidsBed] = useState(2);
  const [breakfastPrice, setBreakfastPrice] = useState(0.00);
  const [lunchPrice, setLunchPrice] = useState(0.00);
  const [dinnerPrice, setDinnerPrice] = useState(0.00);
  const [allIDsChecked, setAllIDsChecked] = useState(false);

  const identityProofOptions = ["Aadhar Card", "Passport", "Driving License", "Govt. ID"];

  useEffect(() => {
    const fetchPolicies = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append("token", hostData.host_access_token);
      formData.append("listing_id", listingId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });

        if (response.data.status === "ok") {
          const data = response.data.updated_property_item.policies;
          setCheckInTime(data.checkin_time);
          setCheckOutTime(data.checkout_time);
          setCancellationPolicy(data.cancellation_policy);
          setUnmarriedCouplesAllowed(data.is_unmarried_couples_allowed);
          setMaleOnlyGroupsAllowed(data.is_only_male_group_allowed);
          setAgeRestriction(data.is_minor_guest_allowed);
          setAcceptableIdentityProofs(data.acceptable_id_proofs);
          setSmokingAllowed(data.is_smoking_allowed);
          setPartiesAllowed(data.is_private_party_allowed);
          setVisitorAccessAllowed(data.is_outside_visitor_allowed);
          setWheelchairAccessible(data.is_wheelchair_guest_accessible);
          setPetAllowed(data.is_pet_allowed);
          setPropertyPet(data.is_pet_in_property);
          setFlexibleCheckin(data.is_flexible_checkin_allowed);
          setExtraAdultBed(data.extra_bed_for_adults);
          setExtraKidsBed(data.extra_bed_for_kids);
          setDescription(data.custom_policy);
          setBreakfastPrice(data.breakfast_price);
          setLunchPrice(data.lunch_price);
          setDinnerPrice(data.dinner_price);
        } else {
          toast.error("Failed to fetch policies data.");
        }
      } catch (error) {
        //toast.error("An error occurred while fetching data.");
        console.error(error);
      }
    };

    fetchPolicies();
  }, [listingId, params]);

  const toggleAllIDs = () => {
    const newIDs = allIDsChecked ? [] : identityProofOptions;
    setAllIDsChecked(!allIDsChecked);
    setAcceptableIdentityProofs(newIDs);
  };

  const savePolicies = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const policyData = [{
      stay_listing_id : listingId,
      checkin_time : checkInTime,
      checkout_time : checkOutTime,
      cancellation_policy : cancellationPolicy,
      is_unmarried_couples_allowed : unmarriedCouplesAllowed,
      is_minor_guest_allowed : ageRestriction,
      is_only_male_group_allowed : maleOnlyGroupsAllowed,
      acceptable_id_proofs : acceptableIdentityProofs,
      is_smoking_allowed : smokingAllowed,
      is_private_party_allowed : partiesAllowed,
      is_outside_visitor_allowed : visitorAccessAllowed,
      is_wheelchair_guest_accessible : wheelchairAccessible,
      is_pet_allowed : petAllowed,
      is_pet_in_property : propertyPet,
      is_flexible_checkin_allowed : flexibleCheckin,
      extra_bed_for_adults : extraAdultBed,
      extra_bed_for_kids : extraKidsBed,
      custom_policy : description,
      breakfast_price : breakfastPrice,
      lunch_price : lunchPrice,
      dinner_price : dinnerPrice,
    }];

    let formData = new FormData();
    formData.append("token", hostData.host_access_token);
    formData.append("listing_id", listingId);
    formData.append("data", JSON.stringify({policy: policyData}));

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: formData,
      });

      if (response.data.status === "done") {
        //toast.success("Policies saved successfully.");
        nextStep();
      } else {
        toast.error("Failed to save policies.");
      }
    } catch (error) {
      toast.error("An error occurred while saving policies.");
      console.error(error);
    }
  };

  const handleNext = () => {
    savePolicies();
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= charLimit) {
      setDescription(value);
    }
  };

  const options = [
    "Passport",
    "Aadhar",
    "Driving License",
    "Govt. ID",
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (value) => {
    setSelectedItems((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const handleCheckAll = () => {
    if (isAllChecked) {
      setSelectedItems([]);
    } else {
      setSelectedItems(options);
    }
    setIsAllChecked(!isAllChecked);
  };

  const getLabel = () => {
    const count = selectedItems.length;
    if (count === 0) return "Select";
    if (count === 1) return selectedItems[0];
    if (count === 2) return `${selectedItems[0]}, ${selectedItems[1]}`;
    return `${count} items selected`;
  };

  return (
    <div>

        <div className="add-room-section hmfsb">

          <div className="clp-header">
              <h4 className='ws-title'>Policies</h4>
              <p className="ws-desc">Mention all the policies applicable at your property.</p>
          </div>

          <div className="room-detail-box">
            <div>
              <p className='rdb-title mb-0'>Check-in & Check-out Time</p>
              <p className="label-hint">Specify the check-in & check-out time at your property</p>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div class="form-box">
                  <label for="checkInTime" class="form-label">Check-in Time</label>
                  <select class="form-select" value={checkInTime} onChange={(e) => setCheckInTime(e.target.value)}>
                      <option value="FLEXIBLE">FLEXIBLE</option>
                      <option value="08:00 AM">08:00 AM</option>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="12:00 AM">12:00 AM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                  </select>
                </div>
              </div>
              <div className='col-md-6'>
                <div class="form-box">
                  <label for="checkOutTime" class="form-label">Check-out Time</label>
                  <select class="form-select" value={checkOutTime} onChange={(e) => setCheckOutTime(e.target.value)}>
                      <option value="12:00 AM">12:00 AM</option>
                      <option value="01:00 AM">01:00 AM</option>
                      <option value="02:00 AM">02:00 AM</option>
                      <option value="03:00 AM">03:00 AM</option>
                      <option value="04:00 AM">04:00 AM</option>
                      <option value="05:00 AM">05:00 AM</option>
                      <option value="06:00 AM">06:00 AM</option>
                      <option value="07:00 AM">07:00 AM</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="room-detail-box">
            <div>
              <p className='rdb-title mb-0'>Cancellation Policy</p>
              <p className="label-hint">Select a suitable cancellation policy</p>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="cancellationPolicy" id="24hours" value="1" checked={cancellationPolicy === 1} onChange={() => setCancellationPolicy(1)} />
                  <label class="form-check-label" for="24hours">
                    Free cancellation upto 24 hrs
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="cancellationPolicy" id="48hours" value="2" checked={cancellationPolicy === 2} onChange={() => setCancellationPolicy(2)} />
                  <label class="form-check-label" for="48hours">
                    Free cancellation upto 48 hrs
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="cancellationPolicy" id="72hours" value="3" checked={cancellationPolicy === 3} onChange={() => setCancellationPolicy(3)} />
                  <label class="form-check-label" for="72hours">
                    Free cancellation upto 72 hrs
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="cancellationPolicy" id="nonRefundable" value="4" checked={cancellationPolicy === 4} onChange={() => setCancellationPolicy(4)} />
                  <label class="form-check-label" for="nonRefundable">
                    Non Refundable
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="room-detail-box">
            <div>
              <p className='rdb-title mb-0'>Property Rules(optional)</p>
              <p className="label-hint">Add property rules basis the requirement of your property listing</p>
            </div>
            
            <div className="polic-sub-section">
              <div className="hpsb">
                <p className="rdb-title">Guest Profile</p>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Do you allow unmarried couples?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="unmarriedCouple" value="2" checked={unmarriedCouplesAllowed === 2} onChange={() => setUnmarriedCouplesAllowed(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="unmarriedCouple" value="1" checked={unmarriedCouplesAllowed === 1} onChange={() => setUnmarriedCouplesAllowed(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Do you allow guests below 18 years of age at your property?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="minorGuest" value="2" checked={ageRestriction === 2} onChange={() => setAgeRestriction(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="minorGuest" value="1" checked={ageRestriction === 1} onChange={() => setAgeRestriction(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Groups with only male guests are allowed at your property?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="maleGuest" value="2" checked={maleOnlyGroupsAllowed === 2} onChange={() => setMaleOnlyGroupsAllowed(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="maleGuest" value="1" checked={maleOnlyGroupsAllowed === 1} onChange={() => setMaleOnlyGroupsAllowed(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="polic-sub-section">
              <div className="hpsb">
                <p className="rdb-title">Acceptable Identity Proofs</p>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div class="form-box">
                    <label for="roomCount" class="form-label">Acceptable Identity Proofs</label>
                    <div className="dropdown" data-control="checkbox-dropdown">
                      <label className="dropdown-label form-select" onClick={toggleDropdown}>
                        {getLabel()}
                      </label>

                      {isOpen && (
                        <div className="dropdown-list">
                          <a
                            href="#"
                            className="dropdown-option"
                            data-toggle="check-all"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCheckAll();
                            }}
                          >
                            {isAllChecked ? "Uncheck All" : "Check All"}
                          </a>
                          {options.map((option) => (
                            <label key={option} className="dropdown-option">
                              <input
                                type="checkbox"
                                name="dropdown-group"
                                value={option}
                                checked={selectedItems.includes(option)}
                                onChange={() => handleCheckboxChange(option)}
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Are IDs of the same city as the property allowed?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="sameCityId" value="2" checked={sameCityIdAllowed === 2} onChange={() => setSameCityIdAllowed(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="sameCityId" value="1" checked={sameCityIdAllowed === 1} onChange={() => setSameCityIdAllowed(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="polic-sub-section">
              <div className="hpsb">
                <p className="rdb-title">Property Restrictions</p>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Is smoking allowed anywhere within the premises? (Select 'No' if it's not permitted, even in outdoor spaces like balconies or lawns, or any designated smoking area)</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="smookingAllowed" value="2" checked={smokingAllowed === 2} onChange={() => setSmokingAllowed(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="smookingAllowed" value="1" checked={smokingAllowed === 1} onChange={() => setSmokingAllowed(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Are Private parties or events allowed at the property?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="privateParties" value="2" checked={partiesAllowed === 2} onChange={() => setPartiesAllowed(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="privateParties" value="1" checked={partiesAllowed === 1} onChange={() => setPartiesAllowed(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Can guests invite any outside visitors in the room during their stay?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="outsideVisitor" value="2" checked={visitorAccessAllowed === 2} onChange={() => setVisitorAccessAllowed(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="outsideVisitor" value="1" checked={visitorAccessAllowed === 1} onChange={() => setVisitorAccessAllowed(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Is your property accessible for guests who use a wheelchair?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="wheelchairGuest" value="2" checked={wheelchairAccessible === 2} onChange={() => setWheelchairAccessible(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="wheelchairGuest" value="1" checked={wheelchairAccessible === 1} onChange={() => setWheelchairAccessible(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="polic-sub-section">
              <div className="hpsb">
                <p className="rdb-title">Pet Policy</p>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Are Pets Allowed?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="petAllowed" value="2" checked={petAllowed === 2} onChange={() => setPetAllowed(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="petAllowed" value="1" checked={petAllowed === 1} onChange={() => setPetAllowed(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Any Pet(s) living on the property?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="propertyPet" value="2" checked={propertyPet === 2} onChange={() => setPropertyPet(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="propertyPet" value="1" checked={propertyPet === 1} onChange={() => setPropertyPet(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="polic-sub-section">
              <div className="hpsb">
                <p className="rdb-title">Checkin and Checkout Policies</p>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div>
                      <p>Do you have a 24-hour check-in?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="flexibleCheckin" value="2" checked={flexibleCheckin === 2} onChange={() => setFlexibleCheckin(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="flexibleCheckin" value="1" checked={flexibleCheckin === 1} onChange={() => setFlexibleCheckin(1)} />
                          <span>Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="polic-sub-section">
              <div className="hpsb">
                <p className="rdb-title">Extra Bed Policies</p>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div style={{width:'auto'}}>
                      <p>Do you provide bed to extra adults?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="extraAdultBed" value="2" checked={extraAdultBed === 2} onChange={() => setExtraAdultBed(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="extraAdultBed" value="1" checked={extraAdultBed === 1} onChange={() => setExtraAdultBed(1)} />
                          <span>Yes</span>
                        </label>
                        <label>
                          <input type="radio" name="extraAdultBed" value="3" checked={extraAdultBed === 3} onChange={() => setExtraAdultBed(3)} />
                          <span>Subject to Availability</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='dypxbs'>
                    <div style={{width:'auto'}}>
                      <p>Do you provide bed to extra kids?</p>
                    </div>
                    <div class="extra-bed-radio">
                      <div className="exdrs">
                        <label>
                          <input type="radio" name="extraKidBed" value="2" checked={extraKidsBed === 2} onChange={() => setExtraKidsBed(2)} />
                          <span>No</span>
                        </label>
                        <label>
                          <input type="radio" name="extraKidBed" value="1" checked={extraKidsBed === 1} onChange={() => setExtraKidsBed(1)} />
                          <span>Yes</span>
                        </label>
                        <label>
                          <input type="radio" name="extraKidBed" value="3" checked={extraKidsBed === 3} onChange={() => setExtraKidsBed(3)} />
                          <span>Subject to Availability</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="polic-sub-section">
              <div className="hpsb">
                <p className="rdb-title">Custom Policy</p>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-box'>
                    <label for="roomDesc" class="form-label">Custom Policy</label>
                    <textarea
                      className='form-control'
                      id='roomDesc'
                      rows={5}
                      value={description}
                      onChange={handleDescriptionChange}
                    />
                    <div className='hhtbs'>
                    {charLimit - (description?.length || 0)} characters available
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="polic-sub-section">
              <div className="hpsb">
                <p className="rdb-title">Meal rack prices</p>
              </div>
              <div className="form-box">
                <div class="row">
                  <label for="breakfast" class="col-sm-6">Breakfast</label>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="breakfast" value={breakfastPrice} onChange={(e) => setBreakfastPrice(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="form-box">
                <div class="row">
                  <label for="lunch" class="col-sm-6">Lunch</label>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="lunch" value={lunchPrice} onChange={(e) => setLunchPrice(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="form-box">
                <div class="row">
                  <label for="dinner" class="col-sm-6">Dinner</label>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" id="dinner" value={dinnerPrice} onChange={(e) => setDinnerPrice(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div className='clp-footer'>
          <button className='btn btn-blank' onClick={prevStep}>Back</button>
          <button className='btn btn-black' onClick={handleNext}>Next</button>
        </div>

    </div>
  );
};

export default Policies;
