import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'IMAGE';

const ImageItem = ({ image, index, moveImage, removeImage, isCover, setAsCover }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))} className='image-preview col-md-3'>
      <img src={image.pic || image.preview} alt={`preview-${index}`} className='preview-img' />
      <button type='button' className='remove-image-btn' onClick={() => removeImage(index)}>
      <i class="fas fa-minus"></i>
      </button>
      {isCover ? (
        <span className='cover-photo-label'>Cover Photo</span>
      ) : (
        <button className='set-cover-btn' onClick={() => setAsCover(index)}>
          Set as Cover
        </button>
      )}
    </div>
  );
};

const ListingStep8 = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const [images, setImages] = useState([]);
  const [coverPhotoIndex, setCoverPhotoIndex] = useState(0);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file, index) => ({
      file,
      preview: URL.createObjectURL(file),
      id: `img${images.length + index + 1}`
    }));

    setImages((prevImages) => {
      const updatedImages = [...prevImages, ...newImages];
      if (prevImages.length === 0 && newImages.length > 0) {
        setCoverPhotoIndex(0);
      }
      return updatedImages;
    });
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImages(updatedImages);

    if (coverPhotoIndex === fromIndex) {
      setCoverPhotoIndex(toIndex);
    } else if (coverPhotoIndex === toIndex) {
      setCoverPhotoIndex(fromIndex);
    }
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);

    if (index === coverPhotoIndex) {
      setCoverPhotoIndex(0);
    } else if (index < coverPhotoIndex) {
      setCoverPhotoIndex((prev) => prev - 1);
    }
  };

  const setAsCover = (index) => {
    setCoverPhotoIndex(index);
  };

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;
          const { photos = [], cover_photo } = listingData;

          const photoData = photos.map((photo) => ({
            id: photo.stay_listing_photo_id,
            pic: photo.pic,
            isCover: photo.is_cover === 1,
          }));

          setImages(photoData);
          const coverIndex = photoData.findIndex((photo) => photo.isCover);
          if (coverIndex !== -1) setCoverPhotoIndex(coverIndex);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
  }, [listingId]); 

  const saveListing = async () => {

    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;

    const formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('listing_id', listing_id);

    const existingImageIds = [];

    images.forEach((image, index) => {
      if (image.file) {
        formData.append(image.id, image.file);
      } else {
        existingImageIds.push(image.id);
      }
    });

    if (existingImageIds.length > 0) {
      formData.append('ids', existingImageIds.join(','));
    }

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.updateImages}`,
        data: formData,
      });
  
      if (response.data.status === 'completed') {
        console.log(coverPhotoIndex);
        await saveAsCover(coverPhotoIndex);
        nextStep();
      } else if (response.data.status === 'error') {
        response.data.list.forEach((errorMsg) => {
            toast.error(errorMsg);
        });
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const saveAsCover = async (index) => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const imageId = images[index].id;
    console.log(index);
    console.log(imageId);

    const formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('id', imageId);
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.makeItCover}`,
        data: formData,
      });
  
      if (response.data.status === 'completed') {
        console.log('Cover photo updated successfully');
      } else {
        console.log('Failed to update cover photo');
      }
    } catch (error) {
      console.log('An error occurred while updating the cover photo');
    }
  };

  const handleNext = () => {
    saveListing();
    //nextStep();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='clss'>
        <h4 className='ws-title'>Upload photos of your place</h4>
        <p className='ws-desc'>Start with 5 photos; you can add more or make changes later.</p>

        <div className='st1-desc-box'>
          {images.length > 0 && (
            <div className='cover-photo-box'>
              <h6 className='cp-label'>Cover Photo</h6>
              <img
                src={images[coverPhotoIndex].preview ? images[coverPhotoIndex].preview : images[coverPhotoIndex].pic}
                alt="cover-photo"
                className="cover-photo-preview"
              />
            </div>
          )}

          <div className='image-upload-container'>
            <div className='row image-preview-container'>
              {images.map((image, index) => (
                <ImageItem
                  key={index}
                  index={index}
                  image={image}
                  moveImage={moveImage}
                  removeImage={removeImage}
                  isCover={index === coverPhotoIndex}
                  setAsCover={setAsCover}
                />
              ))}
            </div>

            <label htmlFor="file" class="custum-file-upload">
              <div class="icon">
                <div class="text">
                  <span><i class="fas fa-plus"></i></span>
                </div>
                <input id="file" type='file' accept='image/*' multiple onChange={handleImageChange} />
              </div>
            </label>
          </div>
        </div>

        <div className='clp-footer'>
          <button className='btn btn-blank' onClick={prevStep}>Back</button>
          <button className='btn btn-black' onClick={handleNext}>Next</button>
        </div>
      </div>
    </DndProvider>
  );
};

export default ListingStep8;
