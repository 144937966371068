export const applyCategoryTheme = (categoryId, categoryColors) => {
    if (categoryId && categoryColors[categoryId]) {
      const { background, textColor, buttonColor, borderColor } = categoryColors[categoryId];
      document.documentElement.style.setProperty("--background-color", background);
      document.documentElement.style.setProperty("--text-color", textColor);
      document.documentElement.style.setProperty("--button-color", buttonColor);
      document.documentElement.style.setProperty("--border-color", borderColor);
    } else {
      // Reset to default
      document.documentElement.style.setProperty("--background-color", "#f0f0f0");
      document.documentElement.style.setProperty("--text-color", "#000");
      document.documentElement.style.setProperty("--button-color", "#007bff");
      document.documentElement.style.setProperty("--border-color", "#000");
    }
  };
  