import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';

const ListingStep13 = ({ nextStep, prevStep, formData }) => {

  const { listingId } = formData;
  const [reservationOption, setReservationOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;
          try {
            const { resrv_list = [], selected = listingData.first_reservation } = listingData;
            setReservationOption(resrv_list);
            setSelectedOption(selected);
          } catch (error) {
            toast.error('Error parsing listing data: ' + error.message);
            console.error('Parsing error:', error);
          }
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
  }, [listingId]); 

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const updatedPropertyItem = {
      first_reservation: selectedOption,
      resrv_list: reservationOption
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleOptionChange = (id) => {
    setSelectedOption(id);
  };

  const handleNext = () => {
    saveListing();
  };

  return (
    <div>
      <h4 className='ws-title'>Choose your first guest</h4>
      <p class="ws-desc">Then anyone can book your Wire Stay afterward.</p>

      <div className='st1-desc-box'>
        <div className='row'>
          
        {reservationOption.length > 0 ? (
          reservationOption.map((option) => (
            <div key={option.id} className='col-md-12 lb mb-3'>
              <label className='radio-check-label'>
                <input 
                  type="radio" 
                  name="rerv_type" 
                  value={option.id} 
                  checked={selectedOption === option.id} 
                  onChange={() => handleOptionChange(option.id)} 
                />
                <span>
                  <div>
                    <p>{option.title}</p>
                    <p>{option.desc}</p>
                  </div>
                  <div className='cal-img'>
                    <i className="fas fa-braille"></i>
                  </div>
                </span>
              </label>
            </div>
          ))
        ) : (
          <p>No options available</p>
        )}

        </div>
      </div>

      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default ListingStep13;
