const Params = {
    weburl : 'http://localhost:3001',
    profileUrl : 'https://profile.wirestay.com/web/images',
    baseURL: 'https://app.statustag.com/sp/api/v1',
    endpoints: {
      countryList : '/country/list',
      stateList : '/country/state-list',
      cityList : '/country/city-list',
      createOtp : '/otp/create-otp',
      verifyOtp : '/otp/verify-otp',
      isUserExist : '/stays-host-profile/is-user-exists',
      saveProfile : '/stays-host-profile/save-profile',
      updateProfile : '/stays-host-profile/update-profile',
      getProfile : '/stays-host-profile/get-profile',
      updateExtraProfile : '/stays-host-extra-profile/save-profile',
      getExtraProfile : '/stays-host-extra-profile/get-profile',
      getTripCategory : '/stays-host/trip-category',
      getMyBooking : '/stays-host/my-bookings',
      getMyCalendar : '/stays-hotel/my-calendar',
      getDaywisePrice : '/stays-host/my-daywise-price',
      updateDaywisePrice : '/stays-hotel/update-daywise-price',
      getRoomList : '/stays-hotel/room-list',
      getMyListings : '/stays-hotel/my-listings',
      checkAffiliate : '/stays-host/check-for-affiliate',
      createListing : '/stays-hotel/init-listing',
      updateListing : '/stays-hotel/update-listing',
      getListingData : '/stays-hotel/get-listing',
      updateImages : '/stays-hotel/update-featured-images',
      makeItCover : '/stays-hotel/make-it-cover-image',
      getCategories : '/stays-host/categories',
      getHotelCategories : '/stays-hotel/hotel-categories',
      getAmenities : '/stays-hotel/amanities',
      findPlaces : '/stays-host/find-places',
      findPlaceById : '/stays-host/find-place-by-id',
      getPendingListings : '/stays-hotel/pending-listings',
      verifyDocument : '/stays-host/verify-document',
      getChatdb : '/stay-chat/getdb',
      chatMyRequests : '/stay-chat/my-requests',
      latestMessages : '/stay-chat/latest-messages',
      sendMessage : '/stay-chat/send-message',
      notificationCategory : '/stay-notification/get-host-category',
      notificationList : '/stay-notification/notification-list',
    },
  };
  
  export default Params;
  