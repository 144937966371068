import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import params from '../../../config/Params';
import { getItemFromLocalStorage } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';

const Legal = ({ formData, nextStep, prevStep }) => {
  const { listingId } = formData;
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const [ownertype, setOwnertype] = useState('');
  const [ownershipDoc, setOwnershipDoc] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscNumber, setIfscNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [isGstin, setIsGstin] = useState(1);
  const [gstNumber, setGstNumber] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [isTanNumber, setIsTanNumber] = useState(1);
  const [tanNumber, setTanNumber] = useState('');

  useEffect(() => {
    const fetchLegal = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append("token", hostData.host_access_token);
      formData.append("listing_id", listingId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });

        if (response.data.status === "ok") {
          const data = response.data.updated_property_item.legal;
          setOwnertype(data.ownership_type);
          setOwnershipDoc(data.ownership_document);
          setAccountNumber(data.owner_bank_account_number);
          setIfscNumber(data.owner_bank_ifsc_number);
          setBankName(data.owner_bank_name);
          setIsGstin(data.is_gstin);
          setGstNumber(data.gstin_number);
          setPanNumber(data.pan_number);
          setIsTanNumber(data.is_tan_number);
          setTanNumber(data.tan_number);
        } else {
          toast.error("Failed to fetch policies data.");
        }
      } catch (error) {
        //toast.error("An error occurred while fetching data.");
        console.error(error);
      }
    };

    fetchLegal();
  }, [listingId, params]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 15 * 1024 * 1024) {
      setSelectedFile(file);
      setFilePreview(URL.createObjectURL(file));
    } else {
      alert("File size exceeds 15 MB or invalid format.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file && file.size <= 15 * 1024 * 1024) {
      setSelectedFile(file);
      setFilePreview(URL.createObjectURL(file));
    } else {
      alert("File size exceeds 15 MB or invalid format.");
    }
  };

  const clearFile = () => {
    setSelectedFile(null);
    setFilePreview(null);
  };

  const saveData = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const legalData = [{
      stay_listing_id : listingId,
      ownership_type : ownertype,
      ownership_document : ownershipDoc,
      owner_bank_account_number : accountNumber,
      owner_bank_ifsc_number : ifscNumber,
      owner_bank_name : bankName,
      is_gstin : isGstin,
      gstin_number : gstNumber,
      pan_number : panNumber,
      is_tan_number : isTanNumber,
      tan_number : tanNumber
    }];

    let formData = new FormData();
    formData.append("token", hostData.host_access_token);
    formData.append("listing_id", listingId);
    formData.append("data", JSON.stringify({legal: legalData}));

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: formData,
      });

      if (response.data.status === "done") {
        toast.success("Listing created successfully!");
        navigate('/my-listings');
        //nextStep();
      } else {
        toast.error("Failed to save policies.");
      }
    } catch (error) {
      toast.error("An error occurred while saving policies.");
      console.error(error);
    }
  };

  const handleNext = () => {
    saveData();
  };

  return (
    <div>
      
        <div className="add-room-section hmfsb">
          <div className="clp-header">
              <h4 className='ws-title'>Finance & Legal</h4>
              <p className="ws-desc">Add finance & legal details for the new listing of your property.</p>
          </div>

          <div className="room-detail-box">
            <div>
              <p className='rdb-title mb-0'>Ownership details</p>
              <p className="label-hint">Provide documents that proves your ownership</p>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <p>Type of ownership does the property have?</p>
                <div class="form-box">
                  <label for="checkInTime" class="form-label">Choose the ownership type</label>
                  <select class="form-select" value={ownertype} onChange={(e) => setOwnershipDoc(e.target.value)}>
                      <option value="1">My Own Property</option>
                      <option value="2">Lease Property</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <p className="mb-0">Upload the registration document of the property</p>
                <p className="label-hint">The address on the registration document should match with the property address</p>
                <div
                  className={`upload-box ${filePreview ? "has-file" : ""}`}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  {filePreview ? (
                    <div className="file-preview">
                      {selectedFile.type.startsWith("image/") ? (
                        <img src={filePreview} alt="Preview" className="img-preview" />
                      ) : (
                        <p className="file-preview-text">Preview not available for PDF files.</p>
                      )}
                      <button type="button" className="btn-clear" onClick={clearFile}>
                        Remove File
                      </button>
                    </div>
                  ) : (
                    <div className="upload-instructions">
                      <p>Drag & Drop the Registration Document</p>
                      <p>or</p>
                      <label htmlFor="fileInput" className="upload-label">
                        <span>Click Here</span>
                      </label>
                      <p className="file-hint">(Upload PDF/PNG/JPG/JPEG files of up to 15 MB)</p>
                      <input
                        type="file"
                        id="fileInput"
                        className="file-input"
                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        onChange={handleFileChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="add-room-section hmfsb">
          <div className="room-detail-box">
            <div>
              <p className='rdb-title mb-0'>Banking Details</p>
              <p className="label-hint">Enter Bank, PAN & GST Details</p>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <p>Bank Details</p>
              </div>
              <div className='col-md-6'>
                <div class="form-box">
                  <label for="accountNumber" class="form-label">Account Number</label>
                  <input type="text" class="form-control" id="accountNumber" placeholder="Enter Account Number" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                </div>
              </div>
              <div className='col-md-6'>
                <div className="form-box">
                  <label for="reAccountNumber" class="form-label">Re-enter Account Number</label>
                  <input type="text" class="form-control" id="reAccountNumber" placeholder="Enter Account Number" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                </div>
              </div>
              <div className='col-md-6'>
                <div className="form-box">
                  <label for="ifsc" class="form-label">IFSC Number</label>
                  <input type="text" class="form-control" id="ifsc" placeholder="Enter IFSC Number" value={ifscNumber} onChange={(e) => setIfscNumber(e.target.value)} />
                </div>
              </div>
              <div className='col-md-6'>
                <div className="form-box">
                  <label for="bankName" class="form-label">Bank Name</label>
                  <input type="text" class="form-control" id="bankName" placeholder="Enter Bank Name" value={bankName} onChange={(e) => setBankName(e.target.value)} />
                </div>
              </div>
            </div>

            <hr></hr>

            <div className="room-detail-box">
              <div className='dypxbs'>
                <div>
                  <p>Do you have a GSTIN?</p>
                </div>
                <div class="extra-bed-radio">
                  <div className='exdrs'>
                    <label>
                      <input type="radio" name="isGstin" value="2" checked={isGstin === 2} onChange={() => setIsGstin(2)} />
                      <span>No</span>
                    </label>
                    <label>
                      <input type="radio" name="isGstin" value="1" checked={isGstin === 1} onChange={() => setIsGstin(1)} />
                      <span>Yes</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='row'>
                {isGstin === 1 && (
                  <div className='col-md-6'>
                    <div className="form-box">
                      <label for="gstin" class="form-label">Enter GSTIN</label>
                      <input type="text" class="form-control" id="gstin" placeholder="Enter the 15 digit GSTIN" value={gstNumber} onChange={(e) => setGstNumber(e.target.value)} />
                    </div>
                  </div>
                )}
                <div className='col-md-6'>
                  <div className="form-box">
                    <label for="pancard" class="form-label">Enter PAN</label>
                    <input type="text" class="form-control" id="pancard" placeholder="Enter PAN" value={panNumber} onChange={(e) => setPanNumber(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>

            <hr></hr>

            <div className="room-detail-box">
              <div className='dypxbs'>
                <div>
                  <p>Do you have a TAN?</p>
                </div>
                <div class="extra-bed-radio">
                  <div className='exdrs'>
                    <label>
                      <input type="radio" name="isTanNumber" value="2" checked={isTanNumber === 2} onChange={() => setIsTanNumber(2)} />
                      <span>No</span>
                    </label>
                    <label>
                      <input type="radio" name="isTanNumber" value="1" checked={isTanNumber === 1} onChange={() => setIsTanNumber(1)} />
                      <span>Yes</span>
                    </label>
                  </div>
                </div>
              </div>
              {isTanNumber === 1 && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-box">
                      <label htmlFor="tanNumber" class="form-label">Enter TAN</label>
                      <input type="text" class="form-control" id="tanNumber" placeholder="Enter the 12 digit TAN" value={tanNumber} onChange={(e) => setTanNumber(e.target.value)} />
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>

        <div className='clp-footer'>
          <button className='btn btn-blank' onClick={prevStep}>Back</button>
          <button className='btn btn-black' onClick={handleNext}>Submit</button>
        </div>
    </div>
  );
};

export default Legal;
