import React from 'react';
import { Link } from 'react-router-dom';

const Topbar = () => {

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">

      <div className="d-flex align-items-center justify-content-between">
        <Link to="/home" className="logo d-flex align-items-center">
          <span className="d-none d-lg-block">
            <img src={'https://wirestay.com/assets/images/header_logo.svg'} alt="store icon" className="fs-logo" />
          </span>
        </Link>
        {/*<i className="bi bi-list toggle-sidebar-btn"></i>*/}
      </div>

      <nav className="header-nav m-auto">
          
        <ul className="d-flex align-items-center">

          <li className='pe-4'>
            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/home">
              Home
            </Link>
          </li>
          <li className='pe-4'>
            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/my-calendar">
              Calendar
            </Link>
          </li>

          <li className='pe-4'>
            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/my-listings">
              Listings
            </Link>
          </li>

          <li className='pe-4'>
            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/messages">
              Messages
            </Link>
          </li>

          <li className="nav-item dropdown pe-4">

            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="javascript:void(0);" data-bs-toggle="dropdown">
              Menu
            </Link>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">

              <li>
                <Link className="dropdown-item d-flex align-items-center" to="javascript:void(0);">
                  <span>Earnings</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item d-flex align-items-center" to="javascript:void(0);">
                  <span>Insights</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item d-flex align-items-center" to="/create-listing/overview">
                  <span>Create a new listing</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item d-flex align-items-center" to="/profile/profile-information">
                  <span>Personal Details</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item d-flex align-items-center" to="/logout">
                  <span>Logout</span>
                </Link>
              </li>

            </ul>
          </li>

        </ul>
      </nav>

      <nav className="header-nav ms-auto">
          
        <ul className="d-flex align-items-center">

          <li className='pe-3'>
            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/notifications">
              <img src={'/assets/img/icons/bell.svg'} alt="Notification" className="noti-icon" />
            </Link>
          </li>

          <li className="pe-3">
            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/my-profile">
              <img src={'/assets/img/ws_user_icon.png'} alt="Profile" className="user-circle" />
            </Link>
          </li>

        </ul>
      </nav>

    </header>
    
  );
};

export default Topbar;
