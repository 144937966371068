import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import BasicInfo from "./components/BasicInfo";
import Location from "./components/Location";
import Amanities from "./components/Amanities";
import Rooms from "./components/Rooms";
import Photos from "./components/Photos";
import Policies from "./components/Policies";
import Legal from "./components/Legal";

const Index = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [completedSteps, setCompletedSteps] = useState([]);

  const steps = [
    { title: "Basic Info", component: 
      <BasicInfo formData={formData} setFormData={setFormData}
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)} 
      /> },
    { title: "Location", component: 
      <Location formData={formData} setFormData={setFormData} 
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Amenities", component: 
      <Amanities formData={formData} setFormData={setFormData}
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Rooms", component: 
      <Rooms formData={formData} 
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Photos and Videos", component: 
      <Photos formData={formData} 
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Policies", component: 
      <Policies formData={formData} 
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Finance & Legal", component: 
      <Legal formData={formData} 
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
  ];

  const isStepComplete = () => {
    switch (currentStep) {
      default:
        return true;
    }
  };

  const handleNext = () => {
    if (isStepComplete()) {
      if (!completedSteps.includes(currentStep)) {
        setCompletedSteps([...completedSteps, currentStep]);
      }
      setCurrentStep(currentStep + 1);
    } else {
      alert("Please complete this step before proceeding.");
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };

  const handleTabClick = (index) => {
    if (index <= currentStep || completedSteps.includes(index)) {
        setCurrentStep(index);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Hotel Listing</title>
        <meta name='description' content='' />
      </Helmet>

      {/* Tab Navigation */}
      <div className="tabs">
        {steps.map((step, index) => (
          <button
            key={index}
            className={`tab ${currentStep === index ? "active" : ""} ${
                index > currentStep && !completedSteps.includes(index) ? "disabled" : ""
            }`}
            onClick={() => handleTabClick(index)}
            disabled={index > currentStep && !completedSteps.includes(index)}
          >
            {completedSteps.includes(index) && (
              <span className="tick"><i className="far fa-check-circle"></i></span>
            )}
            {step.title}{" "}
          </button>
        ))}
      </div>

      {/* Step Content */}
      <div className="step-content">{steps[currentStep].component}</div>

    </div>
  );
};

export default Index;
