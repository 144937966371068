import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainLayout = ({ children }) => {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
        <Topbar />
        {/*<Sidebar />*/}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          style={{
            width: '100%',
            maxWidth: '600px',
            padding: '0 15px'
          }}
        />
        <main id="main" class="main">
            <section class="section dashboard">
                {children}
            </section>
        </main>
    </>
  );
};

export default MainLayout;
