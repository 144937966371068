import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../../../config/Params';
import { getItemFromLocalStorage } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';

const BasicInfo = ({ formData, nextStep, prevStep }) => {
    const { listingId } = formData;
    const [loading, setLoading] = useState(true);
    const [propertyName, setPropertyName] = useState('');
    const [starRating, setStarRating] = useState('');
    const [builtYear, setBuiltYear] = useState('');
    const [bookingSince, setBookingSince] = useState('');
    const [emailId, setEmailId] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [landlineNumber, setLandlineNumber] = useState('');

    useEffect(() => {      
        const fetchListingData = async () => {
          const hostData = getItemFromLocalStorage('hostProfile');
          let formData = new FormData();
          formData.append('token', hostData.host_access_token);
          formData.append('listing_id', listingId);
      
          try {
            const response = await axios({
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              url: `${params.baseURL}${params.endpoints.getListingData}`,
              data: formData,
            });
      
            if (response.data.status === 'ok') {
              setPropertyName(response.data.updated_property_item.listing_title);
              setStarRating(response.data.updated_property_item.hotel_star_rating);
              setBuiltYear(response.data.updated_property_item.property_built_year);
              setBookingSince(response.data.updated_property_item.accepting_booking_since);
              setEmailId(response.data.updated_property_item.hotel_email_id);
              setMobileNumber(response.data.updated_property_item.hotel_contact_number);
              setLandlineNumber(response.data.updated_property_item.hotel_landline_number);
            } else {
              toast.error('An error occurred while fetching data.');
            }
          } catch (err) {
            console.log(err.message);
          }
        };
      
        fetchListingData();
      }, []);

      const saveListing = async () => {
        const hostData = getItemFromLocalStorage('hostProfile');
        const listing_id = listingId;
        const ts = Date.now();
      
        const updatedPropertyItem = {
            listing_title: propertyName,
            hotel_star_rating: starRating,
            property_built_year: builtYear,
            accepting_booking_since: bookingSince,
            hotel_email_id: emailId,
            hotel_contact_number: mobileNumber,
            hotel_landline_number: landlineNumber
        };
      
        let catData = new FormData();
        catData.append('token', hostData.host_access_token);
        catData.append('listing_id', listing_id);
        catData.append('data', JSON.stringify(updatedPropertyItem)); 
        catData.append('ts', ts); 
      
        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `${params.baseURL}${params.endpoints.updateListing}`,
            data: catData,
          });
      
          if (response.data.status === 'done') {
            nextStep();
          } else {
            toast.error('Failed to save data');
          }
        } catch (err) {
          toast.error('An error occurred while saving the listing');
        }
      };
      
      const handleNext = () => {
        saveListing();
      };

    return (
      <div>

        <div className="hotel-register-section hmfsb">
            <div className="clp-header">
                <h4 className='ws-title'>Basic Information</h4>
            </div>

            <div className="hotel-form-box">
                <div className="hfbfsp">
                    <div className="row hlibs">
                        <label for="propertyName" className="col-sm-6 hrltb">
                            <div className='form-icon'>
                                <img src='/assets/img/icons/legal_name_icon.svg' className='img-fluid' alt='' />
                            </div>
                            <div>
                                <p>Name of the Property</p>
                                <p className="label-hint">Enter the name as on the property documents</p>
                            </div>
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                className="form-control hrifb"
                                id="propertyName"
                                value={propertyName}
                                onChange={(e) => setPropertyName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row hlibs">
                        <label for="hotelRating" className="col-sm-6 hrltb">
                            <div className='form-icon'>
                                <img src='/assets/img/icons/gst_icon.svg' className='img-fluid' alt='' />
                            </div>
                            <div>
                                <p>Hotel Star Rating</p>
                            </div>
                        </label>
                        <div className="col-sm-6">
                            <select
                                className="form-select hrifb"
                                value={starRating}
                                onChange={(e) => setStarRating(e.target.value)}
                            >
                                <option >select rating</option>
                                <option value="5">5</option>
                                <option value="4">4</option>
                                <option value="3">3</option>
                                <option value="2">2</option>
                                <option value="1">1</option>
                            </select>
                        </div>
                    </div>
                    <div className="row hlibs">
                        <label for="propertyBuilt" className="col-sm-6 hrltb">
                            <div className='form-icon'>
                                <img src='/assets/img/icons/gst_icon.svg' className='img-fluid' alt='' />
                            </div>
                            <div>
                                <p>When was the property built?</p>
                            </div>
                        </label>
                        <div className="col-sm-6">
                            <select
                                className="form-select hrifb"
                                value={builtYear}
                                onChange={(e) => setBuiltYear(e.target.value)}
                            >
                                <option >select a year</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                            </select>
                        </div>
                    </div>
                    <div className="row hlibs">
                        <label for="propertyBuilt" className="col-sm-6 hrltb">
                            <div className='form-icon'>
                                <img src='/assets/img/icons/gst_icon.svg' className='img-fluid' alt='' />
                            </div>
                            <div>
                                <p>Accepting booking since?</p>
                                <p className="label-hint">Since when is this property available for guests to book</p>
                            </div>
                        </label>
                        <div className="col-sm-6">
                            <select
                                className="form-select hrifb"
                                value={bookingSince}
                                onChange={(e) => setBookingSince(e.target.value)}
                            >
                                <option selected>select a year</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="hfb_line"></div>
                <div className="hfbfsp">
                    <div className="hbf-title-box">
                        <p className="hbf-title">How can guests contact the property?</p>
                        <p className="label-hint">These contact details will be shared with the guests when they make a booking</p>
                    </div>
                    <div className="row hlibs">
                        <label for="email" className="col-sm-6 hrltb">
                            <div className='form-icon'>
                                <img src='/assets/img/icons/mail_icon.svg' className='img-fluid' alt='' />
                            </div>
                            <div>
                                <p>Email ID</p>
                            </div>
                        </label>
                        <div className="col-sm-6">
                            <input type="text" class="form-control hrifb" id="email" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                        </div>
                    </div>
                    <div className="row hlibs">
                        <label for="number" className="col-sm-6 hrltb">
                            <div className='form-icon'>
                                <img src='/assets/img/icons/whatsapp_icon_r.svg' className='img-fluid' alt='' />
                            </div>
                            <div>
                                <p>Mobile Number</p>
                            </div>
                        </label>
                        <div className="col-sm-6">
                            <input type="text" class="form-control hrifb" id="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                        </div>
                    </div>
                    <div className="row hlibs">
                        <label for="landline" className="col-sm-6 hrltb">
                            <div className='form-icon'>
                                <img src='/assets/img/icons/whatsapp_icon_r.svg' className='img-fluid' alt='' />
                            </div>
                            <div>
                                <p>Landline Number (optional)</p>
                            </div>
                        </label>
                        <div className="col-sm-6">
                            <input type="text" class="form-control hrifb" id="landline" value={landlineNumber} onChange={(e) => setLandlineNumber(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='clp-footer'>
            <button className='btn btn-blank' onClick={prevStep} disabled>Back</button>
            <button className='btn btn-black' onClick={handleNext}>Next</button>
        </div>

    </div>
  );
};

export default BasicInfo;
