import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import params from '../config/Params';
import { getItemFromLocalStorage, setWithExpiry } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

function MyProfile() {
  const navigate = useNavigate();

  const [sourceId, setSourceId] = useState('');
  const [hostId, setHostId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [gstin, setGstin] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const successMessageRef = useRef(null);

  useEffect(() => {
    if (successMessage && successMessageRef.current) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [successMessage]);

  useEffect(() => {
    const profileData = getItemFromLocalStorage('hostProfile');
    if (profileData) {
      setFirstName(profileData.host_name || '');
      setMiddleName(profileData.host_midname || '');
      setLastName(profileData.host_lastname || '');
      setGstin(profileData.host_gst_no || '');
      setCompanyName(profileData.host_legal_name || '');
      setEmail(profileData.host_contact_email || '');
      setNumber(profileData.host_contact_number || '');
      setHostId(profileData.host_id || '');
      setSourceId(profileData.host_login_id || '');
    }
  }, []);

  const handleGstinChange = async (e) => {
    const value = e.target.value;
    setGstin(value);

    if (value.length === 15) {
      let gstData = new FormData();
      gstData.append('gstin', value);
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.verifyGst}`,
          data: gstData
        });
  
        if (response.data.status === 'ok') {
          setCompanyName(response.data.gst_legal_name);
        } else if (response.data.status === 'error') {
          toast.error(response.data.list);
        } else {
          toast.error('An error occurred while saving data.');
        }
      } catch (err) {
        toast.error('An error occurred while saving data.');
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!firstName) errors.firstName = true;
    if (!lastName) errors.lastName = true;
    if (!email) errors.email = true;
    if (!number) errors.number = true;
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    switch (name) {
      case 'firstName':
        setFirstName(value);
        if (!value) setFormErrors((prev) => ({ ...prev, firstName: true }));
        else setFormErrors((prev) => ({ ...prev, firstName: false }));
        break;
      case 'middleName':
        setMiddleName(value);
        break;
      case 'lastName':
        setLastName(value);
        if (!value) setFormErrors((prev) => ({ ...prev, lastName: true }));
        else setFormErrors((prev) => ({ ...prev, lastName: false }));
        break;
      case 'email':
        setEmail(value);
        if (!value) setFormErrors((prev) => ({ ...prev, email: true }));
        else setFormErrors((prev) => ({ ...prev, email: false }));
        break;
      case 'number':
        setNumber(value);
        if (!value) setFormErrors((prev) => ({ ...prev, number: true }));
        else setFormErrors((prev) => ({ ...prev, number: false }));
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      default:
        break;
    }
  };

  const ProceedRegister = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    const hostData = getItemFromLocalStorage('hostProfile');

    let formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('id', hostId);
    formData.append('host_login_source', 'OTP');
    formData.append('host_login_id', sourceId);   
    formData.append('host_name', firstName);
    formData.append('host_midname', middleName);
    formData.append('host_lastname', lastName);
    formData.append('host_contact_number', number);
    formData.append('host_gst_no', gstin);
    formData.append('host_legal_name', companyName);
    formData.append('host_contact_email', email);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.updateProfile}`,
        data: formData
      });

      if (response.data.status === 'updated') {
        setWithExpiry('hostProfile', JSON.stringify(response.data.profile_data));
        toast.success('Your profile data updated successfully.');
      } else if (response.data.status === 'error') {
        toast.error(response.data.list);
      } else {
        toast.error('Error in saving data.');
      }
    } catch (err) {
      toast.error('An error occurred while saving data.');
    }
  }

  return (
    <div>
      <Helmet>
          <title>My Profile</title>
      </Helmet>
      <section className="registration-form" style={{ height: '100%', overflow: 'hidden' }}>
      
        <div className='row'>
          <div className='col-md-6 register-left-panel'>
          </div>
          <div className='col-md-6 offset-md-6'>
            <div className="form-body">
              <div className="row">
                <div className="form-holder">
                  <div className="form-content">
                    <div className="form-items">
                      <h4 className='register-title mb-2'>Update Profile</h4>
                      <p className='mb-5'>Keep your profile fresh and up-to-date—because first impressions matter!</p>
                      <form className="needs-validation" encType="multipart/form-data" onSubmit={ProceedRegister}>

                        <div className='row'>
                          <div className="col-md-12 mb-4">
                            <div className='form-flex'>
                              <div className='form-icon'>
                                <img 
                                  src='/assets/img/icons/legal_name_icon.svg'
                                  className='form-icon img-fluid'
                                  alt=''
                                />
                              </div>
                              <div className='form-txt'>
                                <div className="form-floating">
                                  <input className={`form-control ${formErrors.firstName ? 'is-invalid' : ''}`} type="text" id="firstName" name="firstName" value={firstName} onChange={handleChange} onKeyUp={handleChange} onKeyDown={(e) => {
                                      if (e.key === " ") e.preventDefault();
                                  }} />
                                  <label htmlFor="firstName">First Name</label>
                                </div>
                                <div className="txt-msg hcode-msg mb-0 mt-2" style={{background:'#e5e4e2',border:'1px solid #e5e4e2'}}>
                                  <p className="pid">Dear Host, your first name will be used as your preferred name until you add one yourself. The preferred name is what users see to know who their host is.</p>
                                </div>
                              </div>
                            </div> 
                          </div>

                          <div className="col-md-12 mb-4">
                            <div className='form-flex'>
                              <div className='form-icon'>
                                <img 
                                  src='/assets/img/icons/legal_name_icon.svg'
                                  className='form-icon img-fluid'
                                  alt=''
                                />
                              </div>
                              <div className='form-txt'>
                                <div className="form-floating">
                                  <input className={`form-control ${formErrors.middleName ? 'is-invalid' : ''}`} type="text" id="middleName" name="middleName" value={middleName} onChange={handleChange} onKeyUp={handleChange}/>
                                  <label htmlFor="middleName">Middle Name</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 mb-4">
                            <div className='form-flex'>
                              <div className='form-icon'>
                                <img 
                                  src='/assets/img/icons/legal_name_icon.svg'
                                  className='form-icon img-fluid'
                                  alt=''
                                />
                              </div>
                              <div className='form-txt'>
                                <div className="form-floating">
                                  <input className={`form-control ${formErrors.lastName ? 'is-invalid' : ''}`} type="text" id="lastName" name="lastName" value={lastName} onChange={handleChange} onKeyUp={handleChange} />
                                  <label htmlFor="lastName">Last Name</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className="col-md-12 mb-4">
                            <div className='form-flex'>
                              <div className='form-icon'>
                                <img 
                                  src='/assets/img/icons/mail_icon.svg'
                                  className='form-icon img-fluid'
                                  alt=''
                                />
                              </div>
                              <div className='form-txt'>
                                <div className="form-floating">
                                  <input className={`form-control ${formErrors.email ? 'is-invalid' : ''}`} type="email" id="email" name="email" value={email} onChange={handleChange} onKeyUp={handleChange} />
                                  <label htmlFor="email">Email ID</label>
                                </div>
                              </div>
                            </div> 
                          </div>

                          <div className="col-md-12 mb-4">
                            <div className='form-flex'>
                              <div className='form-icon'>
                                <img 
                                  src='/assets/img/icons/whatsapp_icon_r.svg'
                                  className='form-icon img-fluid'
                                  alt=''
                                />
                              </div>
                              <div className='form-txt'>
                                <div className="form-floating">
                                  <input className={`form-control ${formErrors.number ? 'is-invalid' : ''}`} type="text" id="number" name="number" value={number} onChange={handleChange} onKeyUp={handleChange} />
                                  <label htmlFor="number">Number</label>
                                </div>
                              </div>
                            </div> 
                          </div>
                        </div>

                        <div className='row'>
                          <div className="col-md-12 mb-4">
                            <div className='form-flex'>
                              <div className='form-icon'>
                                <img 
                                  src='/assets/img/icons/gst_icon.svg'
                                  className='form-icon img-fluid'
                                  alt=''
                                />
                              </div>
                              <div className='form-txt'>
                                <div className="form-floating">
                                  <input className="form-control" type="text" maxlength="15" id="gstin" name="gstin" value={gstin} onChange={handleGstinChange} />
                                  <label htmlFor="gstin">GSTIN (If Applicable)</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 mb-4">
                            <div className='form-flex'>
                              <div className='form-icon'>
                                <img 
                                  src='/assets/img/icons/company_icon.svg'
                                  className='form-icon img-fluid'
                                  alt=''
                                />
                              </div>
                              <div className='form-txt'>
                                <div className="form-floating">
                                  <input className="form-control" type="text" id="companyName" name="companyName" value={companyName} onChange={handleChange} />
                                  <label htmlFor="companyName">Company Name</label>
                                </div>
                              </div>
                            </div>  
                          </div>
                        </div>

                        <div className="form-check tc-box mt-3">
                          <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required checked />
                          <label className="form-check-label"> 
                            I ACCEPT THE <a href={'https://wirestay.com/legal/hosting-agreement'} target='_blank' rel="noreferrer">TERMS & CONDITIONS</a> AND <a href={'https://wirestay.com/legal/privacy'} target='_blank' rel="noreferrer">PRIVACY POLICY</a> TO CONTINUE AS A WIRE STAY HOST
                          </label>
                        </div>

                        <div className="form-button mt-3">
                          <button id="submit" type="submit" className="register-btn">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  );
}

export default MyProfile;
