import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';

const ListingStep16 = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const [checkinTimeStart, setCheckinTimeStart] = useState(null);
  const [checkinTimeEnd, setCheckinTimeEnd] = useState(null);
  const [checkoutTime, setCheckoutTime] = useState(null);

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;
          try {
            setCheckinTimeStart(listingData.checkin_time_start);
            setCheckinTimeEnd(listingData.checkin_time_ends);
            setCheckoutTime(listingData.checkout_time);
        } catch (error) {
            toast.error('Error parsing listing data: ' + error.message);
            console.error('Parsing error:', error);
          }
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
  }, [listingId]); 

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const updatedPropertyItem = {
      checkin_time_start: checkinTimeStart,
      checkin_time_ends: checkinTimeEnd,
      checkout_time: checkoutTime,
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleNext = () => {
    saveListing();
  };

  return (
    <div className='clss'>
      <div className='clp-header'>
        <h4 className='ws-title'>Set Your Property’s Check-In and Check-Out Time!</h4>
        <p className='ws-desc'>
          <span className='d-block mb-1'>Wire Stay allows you to set your property’s check-in and check-out times, but we suggest aligning with standard hotel times, such as 11 AM to 11 AM, for guest convenience.</span>
          <span>If your property remains unbooked, enable the “Last-Minute Discount” option below. This will push your property to the top and notify potential guests with offers and discounts.</span>
        </p>
      </div>

      <div className='st1-desc-box'>
        <div className='row'>

            <div className='col-md-12'>
                <p>Check-in window</p>
            </div>
            <div className="col-md-6 mb-3">
                <div className="form-floating">
                    <select className="form-select" id="check-in" name="check_in" 
                        value={checkinTimeStart}
                        onChange={(e) => setCheckinTimeStart(e.target.value)}
                    >
                      <option></option>
                      <option value="FLEXIBLE">FLEXIBLE</option>
                      <option value="08:00 AM">08:00 AM</option>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="12:00 AM">12:00 AM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                    </select>    
                    <label htmlFor="check-in">Start time</label>
                </div>
            </div>

            <div className="col-md-6 mb-3">
                <div className="form-floating">
                    <select className="form-select" id="check-in" name="check_in"
                        value={checkinTimeEnd}
                        onChange={(e) => setCheckinTimeEnd(e.target.value)}
                    >
                      <option></option>
                      <option value="FLEXIBLE">FLEXIBLE</option>
                      <option value="08:00 AM">08:00 AM</option>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="12:00 AM">12:00 AM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                    </select>    
                    <label htmlFor="check-in">End time</label>
                </div>
            </div>

            <div className="col-md-12 mb-3">
                <p>Checkout time</p>
                <div className="form-floating">
                    <select className="form-select" id="check-out" name="check_out"
                        value={checkoutTime}
                        onChange={(e) => setCheckoutTime(e.target.value)}
                    >
                      <option></option>
                      <option value="12:00 AM">12:00 AM</option>
                      <option value="01:00 AM">01:00 AM</option>
                      <option value="02:00 AM">02:00 AM</option>
                      <option value="03:00 AM">03:00 AM</option>
                      <option value="04:00 AM">04:00 AM</option>
                      <option value="05:00 AM">05:00 AM</option>
                      <option value="06:00 AM">06:00 AM</option>
                      <option value="07:00 AM">07:00 AM</option>
                    </select>    
                    <label htmlFor="check-out">Select time</label>
                </div>
            </div>

        </div>
      </div>

      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default ListingStep16;
