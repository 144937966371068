import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import ThemeWrapper from '../ThemeWrapper ';

const ListingStep3 = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const [placeOptions, setPlaceOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;
          setCategoryId(response.data.updated_property_item.category_id);
          try {
            const { place_options_list = [], selected = listingData.place_option_id } = listingData;
            console.log('Fetched place options:', place_options_list);
            setPlaceOptions(place_options_list);
            setSelectedOption(selected);
          } catch (error) {
            toast.error('Error parsing listing data: ' + error.message);
            console.error('Parsing error:', error);
          }
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
  }, [listingId]); 

  console.log(categoryId);

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const updatedPropertyItem = {
      //place_options: placeOptions.map(option => option.id).join(','),
      place_option_id: selectedOption,
      place_options_list: placeOptions
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleOptionChange = (id) => {
    setSelectedOption(id);
  };

  const handleNext = () => {
    saveListing();
  };

  return (
    <ThemeWrapper categoryId={categoryId}>
      <div>
        <div className='row'>
          <div className='col-md-4 listing-img-box'>
            <div>
              <img src={'/assets/img/listing/step21.png'} alt="Listing Image" className="listing-image" />
            </div>
          </div>

          <div className='col-md-8 offset-md-4'>
            <h4 className='st1-title'>Choose the type of place guests will have</h4>

            <div className='st1-desc-box'>
              <div className='row'>
              {placeOptions.length > 0 ? (
                placeOptions.map((option) => (
                  <div key={option.id} className='col-md-12 lb mb-3'>
                    <label className='radio-check-label'>
                      <input 
                        type="radio" 
                        name="place_type" 
                        value={option.id} 
                        checked={selectedOption === option.id} 
                        onChange={() => handleOptionChange(option.id)} 
                      />
                      <span>
                        <div>
                          <p>{option.title}</p>
                          <p>{option.desc}</p>
                        </div>
                        <div className='cal-img'>
                          <i className="fas fa-braille"></i>
                        </div>
                      </span>
                    </label>
                  </div>
                ))
              ) : (
                <p>No place options available</p>
              )}

              </div>
            </div>

            <div className='clp-footer'>
              <button className='btn btn-blank' onClick={prevStep}>Back</button>
              <button className='btn btn-black' onClick={handleNext}>Next</button>
            </div>
          </div>

        </div>
      </div>
    </ThemeWrapper>
  );
};

export default ListingStep3;
