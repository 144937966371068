import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getItemFromLocalStorage } from '../utils/storageUtils';

const Default = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const sourceId = sessionStorage.getItem('host_id');
    const localHostId = getItemFromLocalStorage('host_id');
    if (sourceId || localHostId) {
      navigate('/home');
    } else {
      navigate('/login');
    }
  }, [navigate]);

};

export default Default;
