import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import Drawer from '@mui/material/Drawer';

const ListingStep18 = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const navigate = useNavigate();
  const [petAllowed, setPetAllowed] = useState(false);
  const [noOfPets, setNoOfPets] = useState(1);
  const [eventAllowed, setEventAllowed] = useState(false);
  const [smokingAllowed, setSmokingAllowed] = useState(false);
  const [commercialAllowed, setCommercialAllowed] = useState(false);
  const [guestAllowed, setGuestAllowed] = useState(1);
  const [additionalRules, setAdditionalRules] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });

        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;
          setPetAllowed(listingData.rules_pet_allowed);
          setNoOfPets(listingData.rules_pet_count || 1);
          setEventAllowed(listingData.rules_event_allowed);
          setSmokingAllowed(listingData.rules_smoking_allowed);
          setCommercialAllowed(listingData.rules_commercial_allowed);
          setGuestAllowed(listingData.rules_no_of_guests_allowed || 1);
          setAdditionalRules(listingData.rules_additional);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.error(err.message);
        toast.error('Failed to load listing data.');
      }
    };

    fetchListingData();
  }, [listingId]);

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const ts = Date.now();

    const updatedPropertyItem = {
      rules_pet_allowed: petAllowed,
      rules_pet_count: noOfPets,
      rules_event_allowed: eventAllowed,
      rules_smoking_allowed: smokingAllowed,
      rules_commercial_allowed: commercialAllowed,
      rules_no_of_guests_allowed: guestAllowed,
      rules_additional: additionalRules,
    };

    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listingId);
    catData.append('data', JSON.stringify(updatedPropertyItem));
    catData.append('ts', ts);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });

      if (response.data.status === 'done') {
        //nextStep();
        navigate('/my-listings');
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleIncrement = (value, setter, max = 10) => {
    if (value < max) {
      setter(value + 1);
    }
  };

  const handleDecrement = (value, setter, min = 1) => {
    if (value > min) {
      setter(value - 1);
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleNext = () => {
    saveListing();
  };

  return (
    <div className='clss'>
      <div className='clp-header'>
        <h4 className='ws-title'>House rules</h4>
        <p className='ws-desc'>Guests are expected to follow your rules, and can be removed from WIRE STAYS if they cause issues.</p>
      </div>

      <div className='st1-desc-box'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='house-rules-box'>
              <div className='pets-box'>
                <div className='rules-txt-box'>
                  <p>Pets allowed</p>
                  <p>You can refuse pets, but must reasonably accommodate service animals</p>
                </div>
                <div className='btn-box'>
                  <label>
                    <input
                      type="checkbox"
                      checked={!petAllowed}
                      onChange={() => setPetAllowed(false)}
                    />
                    <span><i className="fas fa-times"></i></span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={petAllowed}
                      onChange={() => setPetAllowed(true)}
                    />
                    <span><i className="fas fa-check"></i></span>
                  </label>
                </div>
              </div>
              {petAllowed && (
                <div className='allowed-box'>
                  <label className='st1-label'>Maximum number of pets allowed</label>
                  <div className="input-number-container">
                    <span className="input-number-decrement" onClick={() => handleDecrement(noOfPets, setNoOfPets)}>–</span>
                    <input
                      name='pets'
                      className="input-number"
                      type="number"
                      value={noOfPets}
                      readOnly
                    />
                    <span className="input-number-increment" onClick={() => handleIncrement(noOfPets, setNoOfPets)}>+</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='col-md-12'>
            <div className='house-rules-box'>
              <div className='pets-box'>
                <div className='rules-txt-box'>
                  <p>Events allowed</p>
                </div>
                <div className='btn-box'>
                  <label>
                    <input
                      type="checkbox"
                      checked={!eventAllowed}
                      onChange={() => setEventAllowed(false)}
                    />
                    <span><i className="fas fa-times"></i></span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={eventAllowed}
                      onChange={() => setEventAllowed(true)}
                    />
                    <span><i className="fas fa-check"></i></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12'>
            <div className='house-rules-box'>
              <div className='pets-box'>
                <div className='rules-txt-box'>
                  <p>Smoking, vaping, e-cigarettes allowed</p>
                </div>
                <div className='btn-box'>
                  <label>
                    <input
                      type="checkbox"
                      checked={!smokingAllowed}
                      onChange={() => setSmokingAllowed(false)}
                    />
                    <span><i className="fas fa-times"></i></span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={smokingAllowed}
                      onChange={() => setSmokingAllowed(true)}
                    />
                    <span><i className="fas fa-check"></i></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12'>
            <div className='house-rules-box'>
              <div className='pets-box'>
                <div className='rules-txt-box'>
                  <p>Commercial photography and filming allowed</p>
                </div>
                <div className='btn-box'>
                  <label>
                    <input
                      type="checkbox"
                      checked={!commercialAllowed}
                      onChange={() => setCommercialAllowed(false)}
                    />
                    <span><i className="fas fa-times"></i></span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={commercialAllowed}
                      onChange={() => setCommercialAllowed(true)}
                    />
                    <span><i className="fas fa-check"></i></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12'>
            <div className='house-rules-box'>
              <div className='rules-guest-box'>
                <label className='st1-label'>Guests</label>
                <div className="input-number-container">
                    <span className="input-number-decrement" onClick={() => handleDecrement(guestAllowed, setGuestAllowed)}>–</span>
                    <input
                        name='guest'
                        className="input-number"
                        type="number"
                        value={guestAllowed}
                        readOnly
                    />
                    <span className="input-number-increment" onClick={() => handleIncrement(guestAllowed, setGuestAllowed)}>+</span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12'>
            <div className='house-rules-box'>
              <div className='pets-box'>
                <div className='rules-txt-box'>
                  <p>Additional rules</p>
                </div>
                <div className='btn-box'>
                  <button className='add-rule-btn' onClick={toggleDrawer(true)}>
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)}>
        <div style={{ padding: '20px' }}>
          <h3>Additional Rules</h3>
          <textarea
            value={additionalRules}
            onChange={(e) => setAdditionalRules(e.target.value)}
            placeholder="Add additional rules here"
            rows="5"
            style={{ width: '100%', padding: '10px' }}
          />
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <button className='btn btn-black' onClick={toggleDrawer(false)} style={{ marginTop: '10px' }}>
                Close
            </button>
            <button className='btn btn-black' onClick={toggleDrawer(false)} style={{ marginTop: '10px' }}>
                Done
            </button>
          </div>
        </div>
      </Drawer>

      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Save</button>
      </div>
    </div>
  );
};

export default ListingStep18;